import React from 'react'
import searchIcon from '../../images/svg/searchIcon.svg'
const InputSearch = ({placeholder,onChange}) => {
    return(

        <div className='container_input_search'>
            
            <img src={searchIcon}/>
            <input onChange={onChange} placeholder={placeholder} type='text'/>
            

        </div>

    )
}

export default InputSearch