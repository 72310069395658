import React,{createContext,useState} from 'react'
import api from '../services/api'

export const CategoryContext = createContext({})

function CategoryProvider({children}){

    const [categorysModal,setCategorysModal] = useState([])

    const [categoryId,setCategoryId] = useState()
    const [categoryName,setCategoryName] = useState()
    const [subCategoryModal,setSubCategoryModal] = useState()


    const getCategory = (id,name,subCategory) => {
        setCategoryId(id)
        setCategoryName(name)
        setSubCategoryModal(subCategory)
    }

    const getCategoryByName = async (name) => {
        const response = await api.get(`panel/category/${name}`)
        
        if(response.status == 200){
            setCategorysModal(response.data)
            return false
        }else{
            const categorys =  await api.get("panel/get/all/categorys")
            setCategorysModal(categorys.data)
        }

    }



    return(
    <CategoryContext.Provider value={{getCategory,getCategoryByName,categoryId,categoryName,categorysModal,subCategoryModal}}>
        {children}
    </CategoryContext.Provider>
    )

}

export default CategoryProvider