import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import companyIcon from '../../images/png/341042258_794338951634123_4697666895112520661_n 2 (2).png'
import categoriesIcon from '../../images/svg/categoriesIcon.svg'
import exitIcon from '../../images/svg/exitIcon.svg'
import serviceIcon from '../../images/svg/serviceIcon.svg'
import marcasIcon from '../../images/svg/marcasIcon.svg'
import marcasIconCheck from '../../images/svg/marcasIconCheck.svg'
import marketingIcon from '../../images/svg/marketingIcon.svg'
import categoryCheck from '../../images/svg/categoryCheck.svg'
import productIcon from '../../images/svg/productIcon.svg'
import serviceCheckIcon from '../../images/svg/serviceCheckIcon.svg'
import dashboardIcon from '../../images/svg/dashBoardIcon.svg'
import dashboardCheckIcon from '../../images/svg/dashboardCheck.svg'

const Sidebar = () => {
    const [option, setOption] = useState(1)

    const navigate = useNavigate()

    const exit = () => {
        localStorage.clear()
        window.location.href = "/"
    }

    return (

        <div className="sidebar">
            <img className='company_sidebar' src={companyIcon} />

            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/" || window.location.pathname == "/dashboard"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard") }}>
                        <img src={dashboardCheckIcon} />
                        <p>Dashboard</p>
                    </div>
                    :

                    <div className='not_checked' onClick={() => { navigate("/dashboard/") }}>
                        <img src={dashboardIcon} />
                        <p>Dashboard</p>
                    </div>
            }
            <div className='clean_sidebar'></div>


            <div data-toggle="collapse" href="#multiCollapseExample12" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" className='not_checked'>
                <img src={marketingIcon} />
                <p>Marketing</p>


                <svg width="15" className='arrow_product_sidebar' height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1047 4.02326C13.9186 3.83721 13.6163 3.83721 13.4302 4.02326L7.5 9.96512L1.55814 4.02326C1.37209 3.83721 1.06977 3.83721 0.883721 4.02326C0.697675 4.2093 0.697675 4.51163 0.883721 4.69767L7.15116 10.9651C7.24419 11.0581 7.36046 11.1047 7.48837 11.1047C7.60465 11.1047 7.73256 11.0581 7.82558 10.9651L14.093 4.69767C14.2907 4.51163 14.2907 4.2093 14.1047 4.02326V4.02326Z" fill="black" />
                </svg>

            </div>


            <div class="row">

                <div class="col">
                    <div class="collapse multi-collapse" id="multiCollapseExample12">
                        <div className='clean_sidebar'></div>

                        {localStorage.getItem("@PERMISSION") == "5"

                            ?
                            <></>
                            :
                            window.location.pathname == "/dashboard/banners"

                                ?
                                <div className='checked' onClick={() => { navigate("/dashboard/banners") }}>
                                    <img src={categoryCheck} />
                                    <p>Banners</p>
                                </div>

                                :
                                <div className='not_checked' onClick={() => { navigate("/dashboard/banners") }}>
                                    <img src={categoriesIcon} />
                                    <p>Banners</p>
                                </div>
                        }
                        <div className='clean_sidebar'></div>

                    </div>
                </div>
            </div> 


            <div className='clean_sidebar'></div>
            

            <div data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" className='not_checked'>
                <img src={productIcon} />
                <p>Produtos</p>


                <svg width="15" className='arrow_product_sidebar' height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1047 4.02326C13.9186 3.83721 13.6163 3.83721 13.4302 4.02326L7.5 9.96512L1.55814 4.02326C1.37209 3.83721 1.06977 3.83721 0.883721 4.02326C0.697675 4.2093 0.697675 4.51163 0.883721 4.69767L7.15116 10.9651C7.24419 11.0581 7.36046 11.1047 7.48837 11.1047C7.60465 11.1047 7.73256 11.0581 7.82558 10.9651L14.093 4.69767C14.2907 4.51163 14.2907 4.2093 14.1047 4.02326V4.02326Z" fill="black" />
                </svg>

            </div>



            <div class="row">

                <div class="col">
                    <div class="collapse multi-collapse" id="multiCollapseExample1">
                        <div className='clean_sidebar'></div>

                        {localStorage.getItem("@PERMISSION") == "5"

                            ?
                            <></>
                            :
                            window.location.pathname == "/dashboard/models"

                                ?
                                <div className='checked' onClick={() => { navigate("/dashboard/models") }}>
                                    <img src={categoryCheck} />
                                    <p>Categorias</p>
                                </div>

                                :
                                <div className='not_checked' onClick={() => { navigate("/dashboard/models") }}>
                                    <img src={categoriesIcon} />
                                    <p>Categorias</p>
                                </div>
                        }
                        <div className='clean_sidebar'></div>

                        {localStorage.getItem("@PERMISSION") == "5"

                            ?
                            <></>
                            :
                            window.location.pathname == "/dashboard/brands"

                                ?
                                <div className='checked' onClick={() => { navigate("/dashboard/brands") }}>
                                    <img src={marcasIconCheck} />
                                    <p>Marcas</p>
                                </div>

                                :
                                <div className='not_checked' onClick={() => { navigate("/dashboard/brands") }}>
                                    <img src={marcasIcon} />
                                    <p>Marcas</p>
                                </div>
                        }
                        <div className='clean_sidebar'></div>

                        {localStorage.getItem("@PERMISSION") == "5" ?

                            <></>
                            :
                            window.location.pathname == "/dashboard/services"

                                ?
                                <div className='checked' onClick={() => { navigate("/dashboard/services") }}>
                                    <img src={serviceCheckIcon} />
                                    <p>Produtos</p>
                                </div>

                                :
                                <div className='not_checked' onClick={() => { navigate("/dashboard/services") }}>
                                    <img src={serviceIcon} />
                                    <p>Produtos</p>
                                </div>
                        }
                        <div className='clean_sidebar'></div>

                    </div>
                </div>
            </div>



            <div className='not_checked_exit' onClick={() => exit()}>
                <img className='exit_icon' src={exitIcon} />
                <></>
            </div>




        </div>

    )
}

export default Sidebar