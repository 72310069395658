import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import api from '../../services/api';
const TextArea = ({ name,id,description,type }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const content = JSON.stringify(rawContentState);
        localStorage.setItem("@CONTENT_DESCRIPTION", content);
    };


    const handleUpdate = async (field, value) => {
        
        if(type == "update"){
            try {
            
                const response = await api.put("panel/product/update", {
                    field,
                    value,
                    id:id
                });
        
                if (response.status === 200) {
                    // Sucesso na atualização
                    console.log("Produto atualizado com sucesso:", response.data);
                } else {
                    // Tratamento de erro
                    console.error("Erro ao atualizar o produto:", response.data);
                }
            } catch (error) {
                // Tratamento de erro
                console.error("Erro ao atualizar o produto:", error);
            }
        }
        

    };
    
    useEffect(() => {
        if (description) {
            try {
                const contentState = convertFromRaw(JSON.parse(description));
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Failed to load content from description", error);
            }
        }
    }, [description]);

    return (
        <div className="container_input_modal_text_area">
            <label>{name}</label>
            <Editor
                editorState={editorState}
                onBlur={() => handleUpdate()}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleEditorChange}
                editorStyle={{ height: "220px", paddingLeft: "28px", border: "1px solid #D7D7D7", borderRadius: "5px" }}
            />
        </div>
    );
};

export default TextArea;
