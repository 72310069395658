import React,{createContext,useState} from 'react'

export const SchedulesContext = createContext({})

function SchedulesProvider({children}){

    const [scheduleId,setScheduleId] = useState()
    const [userId,setUserId] = useState()
    const [stateSchedule,setStateSchedule] = useState()

    const getSchedule = (id,state) => {
        setScheduleId(id)
        setStateSchedule(state)
    }

    const getUserSchedule = (id) => {

        setUserId(id)
    }


    return(
    <SchedulesContext.Provider value={{getSchedule,scheduleId,stateSchedule,getUserSchedule,userId}}>
        {children}
    </SchedulesContext.Provider>
    )

}

export default SchedulesProvider