import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCbpxgSalWMuR6MoKneBqRJ1O9T29dlnzE",
    authDomain: "storage-saobento.firebaseapp.com",
    projectId: "storage-saobento",
    storageBucket: "storage-saobento.appspot.com",
    messagingSenderId: "636038228741",
    appId: "1:636038228741:web:f846619ffb19abd57b256c",
    measurementId: "G-5QQME16TJX"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


export default app