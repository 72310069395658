import React from 'react'

const ButtonMobile = ({width,height,background,borderRadius,marginTop,name,border,disabled,onClick}) => {

    const styleButton = {

        width:width,
        height:height,
        background:background,
        borderRadius:borderRadius,
        marginTop:marginTop,
        border:border,
        cursor:"pointer"

    }

    const styleButtonDisabled = {

        
        width:width,
        height:height,
        background:"#5c5b5b",
        borderRadius:borderRadius,
        marginTop:marginTop,
        border:border,
    }

    return(
        disabled == true 
        
        ?
        <button disabled={disabled} onClick={onClick} className="button_mobile">{name}</button>

        :

        <button  disabled={disabled} onClick={onClick} className="button_mobile">{name}</button>

    )

}

export default ButtonMobile