import React from 'react'

const CardFinancial = ({ name, value, type,percentage,id}) => {


    return (


        
        <div className='cardFinancial'>

            <p className='textPrimary_financial'>{name}</p>
            {value == 0 
            
            ?
            <p className='textSecondary_financial'>0</p>            
            :

            <p className='textSecondary_financial'>{ id == 1 ? `R$ ${Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : value}</p>


            }

            {type == "receive" 
            
            ?
            <button className='receive_button_card_financial'>Detalhes</button>
            :
            <div className={type == "loss" ? "grafic_financial_loss" : "grafic_financial"}>

            {type == "loss"

                ?
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99473 11.374C8.99473 11.54 9.058 11.6992 9.17062 11.8166C9.28324 11.934 9.43598 12 9.59525 12H14.3995C14.5587 12 14.7115 11.934 14.8241 11.8166C14.9367 11.6992 15 11.54 15 11.374V6.36556C15 6.19952 14.9367 6.04028 14.8241 5.92287C14.7115 5.80547 14.5587 5.73951 14.3995 5.73951C14.2402 5.73951 14.0875 5.80547 13.9748 5.92287C13.8622 6.04028 13.7989 6.19952 13.7989 6.36556V9.62101L9.45953 4.0905C9.40641 4.0229 9.34031 3.96765 9.26546 3.92829C9.19063 3.88894 9.10871 3.86635 9.02499 3.86199C8.94128 3.85762 8.85761 3.87158 8.77938 3.90295C8.70115 3.93433 8.63008 3.98243 8.57076 4.04417L5.46363 7.28335L1.07257 0.989049C0.976515 0.861713 0.836851 0.778152 0.68293 0.755923C0.529009 0.733695 0.372816 0.774532 0.247173 0.869851C0.12153 0.96517 0.0362205 1.10755 0.00917083 1.26707C-0.0178788 1.42659 0.015438 1.59083 0.10212 1.72528L4.90634 8.61182C4.95733 8.68505 5.02295 8.74589 5.09866 8.79014C5.17438 8.83439 5.2584 8.861 5.34493 8.86814C5.43146 8.87528 5.51845 8.86278 5.5999 8.8315C5.68135 8.80022 5.75533 8.7509 5.81674 8.68695L8.95029 5.41897L13.1324 10.7479H9.59525C9.43598 10.7479 9.28324 10.8139 9.17062 10.9313C9.058 11.0487 8.99473 11.2079 8.99473 11.374Z" fill="#D14F4F" />
                </svg>

                :
                <svg width="15" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.49473 0.626049C9.49473 0.460011 9.558 0.300773 9.67062 0.183366C9.78324 0.0659589 9.93598 0 10.0953 0H14.8995C15.0587 0 15.2115 0.0659589 15.3241 0.183366C15.4367 0.300773 15.5 0.460011 15.5 0.626049V5.63444C15.5 5.80048 15.4367 5.95972 15.3241 6.07713C15.2115 6.19453 15.0587 6.26049 14.8995 6.26049C14.7402 6.26049 14.5875 6.19453 14.4748 6.07713C14.3622 5.95972 14.2989 5.80048 14.2989 5.63444V2.37899L9.95953 7.9095C9.90641 7.9771 9.84031 8.03235 9.76546 8.07171C9.69063 8.11106 9.60871 8.13365 9.52499 8.13801C9.44128 8.14238 9.35761 8.12842 9.27938 8.09705C9.20115 8.06567 9.13008 8.01757 9.07076 7.95583L5.96363 4.71665L1.57257 11.011C1.47651 11.1383 1.33685 11.2218 1.18293 11.2441C1.02901 11.2663 0.872816 11.2255 0.747173 11.1301C0.62153 11.0348 0.53622 10.8924 0.509171 10.7329C0.482121 10.5734 0.515438 10.4092 0.60212 10.2747L5.40634 3.38818C5.45733 3.31495 5.52295 3.25411 5.59866 3.20986C5.67438 3.16561 5.7584 3.139 5.84493 3.13186C5.93146 3.12472 6.01845 3.13722 6.0999 3.1685C6.18135 3.19978 6.25533 3.2491 6.31674 3.31305L9.45029 6.58103L13.6324 1.2521H10.0953C9.93598 1.2521 9.78324 1.18614 9.67062 1.06873C9.558 0.951325 9.49473 0.792088 9.49473 0.626049Z" fill="#4FD18B" />
                </svg>
            }


            <p>{percentage}%</p>

        </div>
            }

    

        </div>


    )
}

export default CardFinancial