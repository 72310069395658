import React, { useState, useContext, useEffect } from 'react'
import HeaderDashboard from '../../components/HeaderDashboard'
import Sidebar from '../../components/Sidebar'
import TableList from '../../components/TableList'
import TableListFinancial from '../../components/TableListFinancial'
import Filter from '../../components/Filter'
import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import api from '../../services/api'
import CardFinancial from '../../components/CardFinancial'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import SidebarMobile from '../../components/SidebarMobile'
import { FilterContext } from '../../context/filter'
import { UserContext } from '../../context/user'
import TableListMobile from '../../components/TableListMobile'
import Lottie from 'react-lottie';
import animationData from '../../images/animation/Animation - 1714666317337.json'
import FileSaver from 'file-saver'; // Importe o FileSaver para fazer o download do arquivo
import Axios from 'axios'


const Financial = () => {
    const { get, typeTable } = useContext(FilterContext)
    const { userId } = useContext(UserContext)

    const [table, setTable] = useState(1)

    const [openSidebar, setOpenSidebar] = useState(false)
    const [animation, setAnimation] = useState(null)

    const [total, setTotal] = useState(0)
    const [delivery, setDelivery] = useState(0)
    const [canceled, setCanceled] = useState(0)

    const [percentualIncreasePayment, setPercentualIncreasePayment] = useState(0)
    const [percentualIncreaseDelivery, setPercentualIncreaseDelivery] = useState(0)
    const [percentualIncreaseCanceled, setPercentualIncreaseCanceled] = useState(0)


    async function storage() {
        const token = localStorage.getItem('@USER_TOKEN')


        Axios({
            url: `http://localhost:3333/panel/report/${userId}`, //your url
            method: 'get',
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}` // substitua `token` pelo seu token real
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "scroll",
        overFlowX: "hidden",
        alignItems: "center"
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    const getData = async () => {
        const response = await api.get("payment/get/dashboard")
        setTotal(response.data.totalPayment)
        setCanceled(response.data.canceled)
        setDelivery(response.data.delivery)
        setPercentualIncreasePayment(response.data.percentualIncreasePayment)
        setPercentualIncreaseDelivery(response.data.percentualIncreaseDelivery)
        setPercentualIncreaseCanceled(response.data.percentualIncreaseCanceled)
    }

    // useEffect(() => {

    //     getData()

    // }, [])

    return (

        <ThowColumLayoutDashboard
            background={"#F7F9FB"}
            colum1={
                <Sidebar />
            }

            colum2={


                <div className='container_secondary'>

                    {animation == true


                        ?
                        <div className='container_animation_data'>

                            <div>

                                <Lottie
                                    options={defaultOptions}
                                    height={320}
                                    width={320}
                                />
                            </div>
                        </div>
                        :
                        <></>

                    }

                    <div className='container_data'>

                        <HeaderDashboard name={"Financeiro"} />
                        <Filter type={"financial"} />

                        <div className='row_financial'>
                            <CardFinancial name="Renda total" id={1} percentage={percentualIncreasePayment} value={total} />
                            <CardFinancial name="Total Entregas" value={delivery} percentage={percentualIncreaseDelivery} />
                            <CardFinancial name="Total Perdas" type={"loss"} value={canceled} percentage={percentualIncreaseCanceled} />
                            <CardFinancial name="A receber" type={"receive"} value={0} />

                        </div>
                        <TableListFinancial financial={true} table={2} />

                    </div>



                    <div class="modal fade" id="options" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px", border: "none" }}>
                                    <div className='container_header_modal_user'>
                                        <p>Ações</p>
                                        <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                    </div>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_options'>

                                        <button className='btn_primary_modal_option' onClick={() => storage()}>Gerar Relatório</button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>




                </div>
            }

        >

        </ThowColumLayoutDashboard>

    )
}

export default Financial