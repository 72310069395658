import React, { useState, useEffect, useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import HeaderDashboard from '../../components/HeaderDashboard'
import Sidebar from '../../components/Sidebar'
import TableList from '../../components/TableList'
import Lottie from 'react-lottie';
import InputImageModal from '../../components/InputImageModal'
import Filter from '../../components/Filter'
import firebase from 'firebase/compat/app';
import { UserContext } from '../../context/user'
import { FilterContext } from '../../context/filter'
import InputModal from '../../components/InputModal'
import TableListMobile from '../../components/TableListMobile';
import Input from '../../components/Input';
import SidebarMobile from '../../components/SidebarMobile';
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile';
import InputService from '../../components/InputService'
import Select from '../../components/Select'
import { BigHead } from '@bigheads/core'

import imagePorfolio from '../../images/svg/portfolioImageIcon.svg'
import CardAvaliation from '../../components/CardAvaliation'
import InputMobile from '../../components/InputMobile';
import api from '../../services/api'
import app from '../../firebase/config';
import axios from 'axios'
import animationData from '../../images/animation/Animation - 1714666317337.json'
import { type } from '@testing-library/user-event/dist/type';


const Dashboard = () => {
    const [brazilianStates, setBrazilianStates] = useState([])
    const [userSelectState, setUserSelectState] = useState()
    const [openSidebar, setOpenSidebar] = useState(false)

    const [citys, setCitys] = useState([])
    const [table, setTable] = useState(1)

    const { userImage, userModal, request, closeModal, userId, userName, localServices, handleRemoveService, productId } = useContext(UserContext)
    const { get, typeTable } = useContext(FilterContext)

    /* DELETE USER */
    const [removeUser, setRemoveUser] = useState(false)

    /* USERS DATA */
    const [users, setUsers] = useState([])

    /* USER INFO */

    const [name, setName] = useState()
    const [cpf, setCpf] = useState()
    const [cnpj, setCnpj] = useState()
    const [mail, setMail] = useState()
    const [phone, setPhone] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirm, setPasswordConfirm] = useState()

    /* USER ADDRESS INFO */
    const [cep, setCep] = useState()
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()
    const [neighborhood, setNeighborhood] = useState()
    const [state, setState] = useState()
    const [city, setCity] = useState()

    /* PROVIDER */
    const [nameProvider, setNameProvider] = useState()
    const [cpfProvider, setCpfProvider] = useState()
    const [cnpjProvider, setCnpjProvider] = useState()
    const [mailProvider, setMailProvider] = useState()
    const [phoneProvider, setPhoneProvider] = useState()
    const [imageProviderPreview, setImageProviderPreview] = useState()
    const [itemFirebase, setItemsFirebase] = useState()
    const [avaregeRating, setAverageRating] = useState()
    const [avaliations, setAvaliations] = useState([])

    /* ADRESSES PROVIDER */

    const [cepProvider, setCepProvider] = useState()
    const [streetProvider, setStreetProvider] = useState()
    const [numberProvider, setNumberProvider] = useState()
    const [complementProvider, setComplementProvider] = useState()
    const [neighborhoodProvider, setNeighborhoodProvider] = useState()
    const [stateProvider, setStateProvider] = useState()
    const [citysProvider, setCitysProvider] = useState([])
    const [passwordProvider, setPasswordProvider] = useState()
    const [confirmPasswordProvider, setConfirmPasswordProvider] = useState()
    const [cityProvider, setCityProvider] = useState()
    const [imageProviderFirebase, setImageProviderFirebase] = useState()

    const [items, setItems] = useState([{ image: '', imageFirebase: '', description: '' }]);
    const [imagePreview, setImagePreview] = useState()
    const imageStorage = firebase.storage();

    /* ANIMATION */
    const [animation, setAnimation] = useState(false)

    function resizeImage(file, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type);
            };
            img.onerror = reject;
        });
    }
    const storage = async () => {


        if (nameProvider == undefined) {

            return toast.error('Informe um nome!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (cpfProvider == undefined) {
            return toast.error('Informe um CPF!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


        if (mailProvider == undefined) {
            return toast.error('Informe um e-mail!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (phoneProvider == undefined) {
            return toast.error('Informe um telefone!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (streetProvider == undefined) {
            return toast.error('Informe uma rua!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        setAnimation(true)
        if (imageProviderFirebase) {
            app.storage().ref(`/users/${imageProviderFirebase.name}`).put(imageProviderFirebase).then(() => {
                app.storage().ref(`/users/${imageProviderFirebase.name}`).getDownloadURL().then(async (image) => {
                    try {
                        const response = await api.post("provider/storage", {
                            "name": nameProvider,
                            "cnpj": "",
                            "category": "0",
                            "cpf": cpfProvider,
                            "mail": mailProvider,
                            "stars": "0",
                            "image": image,
                            "phone": phoneProvider,
                            "cep": cepProvider,
                            "street": streetProvider,
                            "number": numberProvider,
                            "complement": complementProvider,
                            "neighborhood": neighborhoodProvider,
                            "state": stateProvider,
                            "city": cityProvider,
                            "password": "0",
                            "password_hash": confirmPasswordProvider
                        });


                        const storagePanel = await api.post("panel/storage", {
                            "name": nameProvider,
                            "cpf": cpfProvider,
                            "mail": mailProvider,
                            "phone": phoneProvider,
                            "password_hash": confirmPasswordProvider,
                            "permission": "5",
                            "image": image
                        })

                        if (response.status === 200) {


                            const idProvider = response.data.user.id

                            const servicePromises = localServices.map(async item => {
                                const serviceResponse = await api.post("provider/storage/service", {
                                    "provider_id": idProvider,
                                    "service_id": item.id,
                                });
                                return serviceResponse;
                            });

                            const portfolioPromises = items.map(async item => {
                                const portfolioResponse = await api.post("provider/storage/portfolio", {
                                    "provider_id": idProvider,
                                    "image": item.imageFirebase,
                                    "comments": item.description
                                });
                                return portfolioResponse;
                            });

                            await Promise.all([...servicePromises, ...portfolioPromises]);

                            toast.success('Fotógrafo adicionado com sucesso!', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            setTimeout(() => {

                                window.location.href = "/dashboard"

                            }, [2000])

                            setAnimation(false)
                        }
                    } catch (error) {
                        // window.alert(error);
                    }
                }).catch(error => {
                    // window.alert(error);
                });
            }).catch(error => {
                // window.alert(error);
            });
            return false;
        }

        const response = await api.post("provider/storage", {
            "name": nameProvider,
            "cpf": cpfProvider,
            "mail": mailProvider,
            "image": "",
            "cnpj": "",
            "category": "",
            "stars": "",
            "phone": phoneProvider,
            "cep": cepProvider,
            "street": streetProvider,
            "number": numberProvider,
            "complement": complementProvider,
            "neighborhood": neighborhoodProvider,
            "state": stateProvider,
            "city": cityProvider,
            "password": "0",
            "password_hash": confirmPasswordProvider
        })

        if (response.status == 200) {
            const idProvider = response.data.user.id

            const servicePromises = localServices.map(async item => {
                return api.post("provider/storage/service", {
                    "provider_id": idProvider,
                    "service_id": item.id
                });
            });


            const portfolioPromises = items.map(async item => {
                return api.post("provider/storage/portfolio", {
                    "provider_id": idProvider,
                    "image": item.imageFirebase,
                    "comments": item.description
                });
            });


            // Espera que todas as promessas sejam concluídas
            await Promise.all([...servicePromises, ...portfolioPromises]);

            toast.success('Fotógrafo adicionado com sucesso!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });


            const storagePanel = await api.post("panel/storage", {
                "name": nameProvider,
                "cpf": cpfProvider,
                "mail": mailProvider,
                "phone": phoneProvider,
                "password_hash": confirmPasswordProvider,
                "permission": "5",
                "image": ""
            })


            setTimeout(() => {

                window.location.href = "/dashboard"

            }, [2000])
        }


    }
    const handleAddItem = () => {

        setItems(prevItems => [...prevItems, { image: '', description: '' }]);
    };
    const handleImageChange = async (index, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const newItems = [...items]; // Copia o array de items

            // Enviar o arquivo para o Firebase Storage
            const storageRef = imageStorage.ref("/portfolios/");
            const fileRef = storageRef.child(file.name);

            try {
                await fileRef.put(file);
                const url = await fileRef.getDownloadURL();
                newItems[index].imageFirebase = url; // Armazena o URL da imagem no estado
            } catch (error) {
                console.error('Erro ao enviar imagem para o Firebase:', error);
            }

            setItems(newItems); // Atualiza o estado com os novos items
        };

        reader.readAsDataURL(file); // Lê o arquivo
    };
    const handleDescriptionChange = (index, event) => {
        const newItems = [...items];
        newItems[index].description = event.target.value;
        setItems(newItems);
        console.log(items)
    };

    const edit = async (field, value) => {


        if (table == 1) {

            const response = await api.put("panel/update/user", {

                [field]: value,
                id: userId

            })


        } else {
            const response = await api.put("panel/update/provider", {

                [field]: value,
                id: userId

            })



        }

    }

    const handleEdit = () => {

        toast.success('Usuário alterado com sucesso!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


        setTimeout(() => {
            window.location.href = "/dashboard"

        }, [2000])

    }

    const getUsers = async () => {
        const response = await api.get("panel/get/all")
        setUsers(response.data)
    }
    const getUser = async () => {

        if (typeTable == 1) {
            const response = await api.get(`panel/get/client/by/id/${userId}`)
            if (response.status == 200) {
                setName(response.data[0].name)
                setCpf(response.data[0].cpf)
                setCnpj(response.data[0].cnpj)
                setMail(response.data[0].mail)
                setPhone(response.data[0].phone)
                setCep(response.data[0].cep)
                setStreet(response.data[0].street)
                setNumber(response.data[0].number)
                setComplement(response.data[0].complement)
                setNeighborhood(response.data[0].neighborhood)
                setUserSelectState(response.data[0].state)
            }

        } else {
            const response = await api.get(`panel/get/provider/by/id/${userId}`)
            if (response.status == 200) {
                console.log(response.data)
                setName(response.data.provider[0].name)
                setCpf(response.data.provider[0].cpf)
                setCnpj(response.data.provider[0].cnpj)
                setMail(response.data.provider[0].mail)
                setPhone(response.data.provider[0].phone)
                setCep(response.data.provider[0].cep)
                setStreet(response.data.provider[0].street)
                setNumber(response.data.provider[0].number)
                setComplement(response.data.provider[0].complement)
                setNeighborhood(response.data.provider[0].neighborhood)
                setUserSelectState(response.data.provider[0].state)
                setAverageRating(response.data.averageRating)
            }
        }

    }
    const getAvaliations = async () => {
        const response = await api.get(`provider/get/avaliation/${userId}`)
        if (response.status == 404) {
            setAvaliations([])
            return false
        }

        if (response.status == 200) {
            setAvaliations(response.data)
        }
    }
    const getCep = async (cep) => {
        setCep(cep)

        try {
            const respose = await fetch(`https://viacep.com.br/ws/${cep}/json/`).then(result => result.json())
            setState(respose.uf)
            setCity(respose.localidade)
            setStreet(respose.logradouro)
        } catch (error) {

        }

    }
    const getCepProvider = async (cep) => {
        setCepProvider(cep)
        const respose = await fetch(`https://viacep.com.br/ws/${cep}/json/`).then(result => result.json())
        console.log(respose)
        setStateProvider(respose.uf)
        setCityProvider(respose.localidade)
        setStreetProvider(respose.logradouro)
    }
    const deleteUser = async () => {

        if (typeTable == 1) {

            const response = await api.delete(`panel/product/${userId}`)
            if (response.status == 200) {

                toast.success('Usuário deletado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                window.location.href = "/dashboard"
                setRemoveUser(!removeUser)
            }

        } else {
            const response = await api.delete(`panel/provider/by/id/${userId}`)
            if (response.status == 200) {

                toast.success('Fotógrafo deletado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                window.location.href = "/dashboard"

                setRemoveUser(!removeUser)
            }
        }


    }
    const blockUser = async () => {
        if (typeTable == 1) {
            const response = await api.put("panel/block/client/", {
                "id": userId
            })

            if (response.status == 200) {
                toast.success('Usuário bloqueado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setRemoveUser(!removeUser)
            }
        }


    }
    const providerImagePreview = (file) => {
        setImageProviderFirebase(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {

            setImageProviderPreview(reader.result)

        }

    }

    useEffect(() => {

        if (typeTable == 1) {
            getUsers()
        }


    }, [typeTable])

    useEffect(() => {
        getAvaliations()

    }, [userId])
    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
            );
            setBrazilianStates(response.data);
        };

        getBrazilianStates();


    }, []);

    useEffect(() => {

        setTable(typeTable)
    }, [typeTable])



    useEffect(() => {

        const getBrazilianStates = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/municipios`
            );



            setCitys(response.data);
        };


        if (userSelectState != undefined) {
            getBrazilianStates();

        }


    }, [userSelectState]);

    useEffect(() => {

        const getBrazilianStates = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateProvider}/municipios`
            );


            setCitysProvider(response.data);
        };


        if (stateProvider != undefined) {
            getBrazilianStates();

        }


    }, [stateProvider]);


    useEffect(() => {

        if (userId != undefined) {

            getUser()

        }


    }, [userId, removeUser])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <ThowColumLayoutDashboard
            background={"white"}
            height={"100vh"}
            colum1={
                <Sidebar />
            }

            colum2={

                <div className='container_secondary'>


                    {animation == true


                        ?
                        <div className='container_animation_data'>

                            <div>

                                <Lottie
                                    options={defaultOptions}
                                    height={320}
                                    width={320}
                                />
                            </div>
                        </div>
                        :
                        <></>

                    }



                    <ToastContainer />

                    <div className='container_data'>
                        <div >
                            <HeaderDashboard name={"Meus Pedidos"} />
                            <Filter type={1} />
                            <TableList table={3} update={removeUser} />
                        </div>
                    </div>



                    <div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-user" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>
                                        <svg onClick={() => closeModal()} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                        <p style={{ marginLeft: "4rem" }} >{table == 2 ? "Perfil do fotógrafo" : "Perfil do usuário"}</p>


                                        <button onClick={() => handleEdit()}>Editar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>

                                        <div className='container_image_modal_user'>
                                            {userImage == ""

                                                ?
                                                <>

                                                    <div style={{ width: "120px", height: "120px" }}>
                                                        <BigHead
                                                            accessory="shades"
                                                            body="chest"
                                                            circleColor="blue"
                                                            clothing="tankTop"
                                                            clothingColor="black"
                                                            eyebrows="angry"
                                                            eyes="wink"
                                                            facialHair="mediumBeard"
                                                            graphic="vue"
                                                            hair="short"

                                                            hairColor="black"
                                                            hat="none"
                                                            hatColor="green"
                                                            lashes="false"
                                                            lipColor="purple"
                                                            mask="true"
                                                            faceMask="true"
                                                            mouth="open"
                                                            skinTone="brown"
                                                        />
                                                    </div>

                                                    <p>{name}</p>

                                                </>
                                                :
                                                <>

                                                    <img src={userImage} />
                                                    <p>{name}</p>


                                                </>
                                            }


                                        </div>

                                        <div className='row_input_modal_user'>
                                            <InputModal onBlur={() => edit("name", name)} value={name} onChange={(e) => setName(e.target.value)} name="Nome completo *" />
                                            <InputModal onBlur={() => edit("cpf", cpf)} value={cpf} onChange={(e) => setCpf(e.target.value)} name="CPF" mask={"999.999.999-99"} />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            <InputModal onChange={(e) => setMail(e.target.value)} onBlur={() => edit("mail", mail)} value={mail} name="E-mail" />
                                            <InputModal onChange={(e) => setPhone(e.target.value)} onBlur={() => edit("phone", phone)} value={phone} name="Celular" mask={"(99) 99999-9999"} />
                                        </div>


                                        <p className='text_type_form'>Endereço</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal value={cep} onChange={(e) => setCep(e.target.value)} onBlur={(e) => { getCep(e.target.value); edit("cep", cep) }} name="CEP" mask={"99999-999"} />
                                            <InputModal onChange={(e) => setStreet(e.target.value)} value={street} onBlur={() => edit("street", street)} name="Rua" />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            <InputModal onChange={(e) => setNumber(e.target.value)} value={number} onBlur={() => edit("number", number)} name="Número" />
                                            <InputModal onChange={(e) => setComplement(e.target.value)} value={complement} name="Complemento" onBlur={() => edit("complement", complement)} />
                                        </div>




                                        {table == 2
                                            ?

                                            <>
                                                <p className='text_type_form'>Avaliações</p>
                                                <div className='scroll_avaliations'>
                                                    {avaliations.map(item => {
                                                        return (
                                                            <CardAvaliation name={""} description={item.service_description} image={item.service_image} />
                                                        )
                                                    })}

                                                </div>
                                            </>
                                            :

                                            <></>

                                        }

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="userProvider" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-user" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>
                                        <button style={{ background: "none" }}>
                                            <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 " fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>

                                        </button>


                                        <div><p>Detalhes pedido</p></div>


                                        <button data-dismiss="modal" aria-label="Close">Fechar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>

                                        <div className='container_image_modal_user'>

                                            <div style={{ width: "124px", height: "124px", marginTop: "-0.2rem" }}>
                                                <BigHead
                                                    accessory="shades"
                                                    body="chest"
                                                    circleColor="blue"
                                                    clothing="tankTop"
                                                    clothingColor="black"
                                                    eyebrows="angry"
                                                    eyes="wink"
                                                    facialHair="mediumBeard"
                                                    graphic="vue"
                                                    hair="short" asd
                                                    hairColor="black"
                                                    hat="none"
                                                    hatColor="green"
                                                    lashes="false"
                                                    lipColor="purple"
                                                    mask="true"
                                                    faceMask="true"
                                                    mouth="open"
                                                    skinTone="brown"
                                                />
                                            </div>

                                            <input name="file_provider" onChange={(e) => providerImagePreview(e.target.files[0])} accept='image/png' id="file_provider" type='file' style={{ display: "none" }} />

                                            <p>{request?.userName}</p>

                                        </div>
                                        <p className='text_type_form'>Dados do pedido</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal value={request?.name_product} onChange={(e) => setNameProvider(e.target.value)} name="Produto *" />
                                            <InputModal value={request?.quantity} onChange={(e) => setCpfProvider(e.target.value)} name="Quantidade" />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            <InputModal value={request?.sku} name="SKU" onChange={(e) => setCnpjProvider(e.target.value)} />
                                            <InputModal value={Number(request?.price).toLocaleString('pt-br',{minimumFractionDigits:2})} name="Preço" onChange={(e) => setCnpjProvider(e.target.value)} />

                                        </div>

                                        <p className='text_type_form'>Dados de entrega</p>


                                        <div className='row_input_modal_user'>
                                            <InputModal value={request?.street} onChange={(e) => setNameProvider(e.target.value)} name="Rua *" />
                                            <InputModal value={request?.number} onChange={(e) => setCpfProvider(e.target.value)} name="Número" />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            <InputModal value={request?.zipCode} onChange={(e) => setNameProvider(e.target.value)} name="CEP *" />
                                            <InputModal value={request?.complement} onChange={(e) => setCpfProvider(e.target.value)} name="Complemento *" />
                                        </div>

                                        <div className='row_input_modal_user'>
                                            <InputModal value={request?.state} onChange={(e) => setNameProvider(e.target.value)} name="Estado *" />
                                            <InputModal value={request?.city} onChange={(e) => setCpfProvider(e.target.value)} name="Cidade *" />
                                        </div>

                                        <div style={{ height: "90px" }}></div>



                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="options" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px", border: "none" }}>
                                    <div className='container_header_modal_user'>
                                        <p>Ações</p>
                                        <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                    </div>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_options'>

                                        <button className='btn_primary_modal_option' onClick={() => blockUser()}>Pronto para envio</button>
                                        <button className='btn_secondary_modal_option' data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#removeUser">Cancelar Pedido</button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="removeUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px" }}>
                                    <div className='container_header_modal_user'>

                                        <div className='row_remove_user_modal'>
                                            <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>
                                            <p>Excluir usuário</p>

                                        </div>
                                    </div>
                                    <button data-dismiss="modal" aria-label="Close" className='remove_user' onClick={() => deleteUser()}>Excluir</button>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_remove'>

                                        <p className='textPrimary_modal_remove'>Tem certeza que deseja cancelar o pedido <b>{request?.name}</b> ?</p>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            }

        >

        </ThowColumLayoutDashboard>
    )
}

export default Dashboard