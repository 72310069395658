import React, { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import activeIcon from '../../images/svg/activeIcon.svg'
import notActiveIcon from '../../images/svg/notActiveIcon.svg'
import blockIcon from '../../images/svg/blockIcon.svg'
import { UserContext } from '../../context/user'
import { FilterContext } from '../../context/filter'
import { BigHead } from '@bigheads/core'

import optionIcon from '../../images/svg/optionIcon.svg'
import notFoundIcon from '../../images/svg/notFoundIcon.svg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const TableListFinancial = ({ table,financial, update }) => {
    const { getUser } = useContext(UserContext)
    const { filter, dataUsers, animation } = useContext(FilterContext)

    useEffect(() => {
        filter(2, "")

    }, [])
    



    return (

        <>

            <table class="gfg">
                <tr>

                    <td style={{ color: "#6A6A6A", width: "470px" }}>Nome completo</td>
                    <td style={{ color: "#6A6A6A", width: "370px" }}>CPF</td>
                    <td style={{ color: "#6A6A6A", width: "370px" }}>E-mail</td>
                    <td style={{ color: "#6A6A6A", width: "370px" }}>Celular</td>
                    <td style={{ color: "#6A6A6A", width: "170px" }}>Status</td>
                    <td style={{ color: "#6A6A6A", width: "240px" }}>Opções</td>

                </tr>


            </table>
            <table class="gfg">


                {dataUsers.length == 0

                    ?

                    <>

                        <td style={{ background: "none", border: "none" }}></td>

                        <td style={{ background: "none", border: "none", width: "620px" }}>

                            <div className='not_found_container'>
                                <img src={notFoundIcon} />
                                <p>Nenhum usuário encontrado :(</p>
                            </div>
                        </td>
                    </>


                    :

                    dataUsers.map(item => {
                        

                        return(

                            animation == null

                            ?

                            <tr>
                                <td style={{width:"100%",border:"none",background:"none"}}><Skeleton style={{width:"100%",height:"50px"}} /></td>
                                <td style={{width:"100%",border:"none",background:"none"}}><Skeleton style={{width:"100%",height:"50px"}} /></td>
                                <td style={{width:"100%",border:"none",background:"none"}}><Skeleton style={{width:"100%",height:"50px"}} /></td>

                            </tr>

                            :

                            <tr>
                                <td style={{ width: "270px" }}>
                                </td>



                                <td style={{ width: "270px" }} data-toggle="modal" data-target={ financial == true ? "#options" : "#userModal"} onClick={() => getUser(item.id, item.image)} >{item.image == "" ? 
                                 <div  className='image_tablelist'>
                                 <BigHead
                                     accessory="shades"
                                     body="chest"
                                     circleColor="blue"
                                     clothing="tankTop"
                                     clothingColor="black"
                                     eyebrows="angry"
                                     eyes="wink"
                                     facialHair="mediumBeard"
                                     graphic="vue"
                                     hair="short" asd
                                     hairColor="black"
                                     hat="none"
                                     hatColor="green"
                                     lashes="false"
                                     lipColor="purple"
                                     mask="true"
                                     faceMask="true"
                                     mouth="open"
                                     skinTone="brown"
                                 />
                             </div>
                                
                                : 
                                
                                <img src={item.image} className='image_tablelist' />}<p className='textPrimaryuserTable'>{item.name != undefined ? item.name : <></>}</p></td>
                                <td style={{ width: "270px" }} data-toggle="modal" data-target="#userModal" onClick={() => getUser(item.id, item.image)}>{item.cpf}</td>
                                <td style={{ width: "270px" }} data-toggle="modal" data-target="#userModal" onClick={() => getUser(item.id, item.image)}>{item.mail}</td>
                                <td style={{ width: "270px" }} data-toggle="modal" data-target="#userModal" onClick={() => getUser(item.id, item.image)}>{item.phone}</td>
                                <td style={{ width: "270px" }} data-toggle="modal" data-target="#userModal" onClick={() => getUser(item.id, item.image)}>{item.id == 1 ? <img src={activeIcon} /> : item.id == 2 ? <img src={notActiveIcon} /> : item.id == 3 ? <img src={blockIcon} /> : <img src={notActiveIcon} />}</td>
                                <td style={{ width: "270px" }} data-toggle="modal" data-target="#options" onClick={() => getUser(item.id, item.image, item.name)}><img src={optionIcon} /></td>

                            </tr>

                        )
                        
                

                    })}




            </table>

        </>

    )
}

export default TableListFinancial