import React from 'react';
import InputMask from 'react-input-mask';
import imageIcon from '../../images/svg/imageIcon.svg';

const InputImageModal = ({ name, type, mask, onChange, onBlur, value, placeholder }) => {
  return (
    type === "file" 
    ? (
      <div className="container_input_modal">
        <label htmlFor="input_image_modal">{name}</label>

        <div className='box_input_modal'>
        <input 
          id="input_image_modal" 
          onBlur={onBlur} 
          onChange={onChange} 
          type={type} 
          className="input_modal"
        />
        <img 
          className="image_file" 
          src={imageIcon} 
          alt="Icone de Imagem" 
          onClick={() => document.getElementById('input_image_modal').click()} 
        />
        <p 
          className="text_image_file" 
          onClick={() => document.getElementById('input_image_modal').click()}
        >
          Selecione
        </p>
      </div>
      </div>
    )
    : (
      <div className="container_input_modal">
        <label htmlFor="input_modal">{name}</label>
        <InputMask 
          id="input_modal" 
          onBlur={onBlur} 
          mask={mask} 
          placeholder={placeholder} 
          onChange={onChange} 
          value={value} 
          type={type} 
          className="input_modal"
        />
      </div>
    )
  );
}

export default InputImageModal;
