import React from 'react'
import ReactStars from 'react-stars'

const CardAvaliation = ({ name, image, description, avaliation }) => {
    return (

        <div className='card_avaliation'>

            <div className='header_card_avaliation'>

                <div>
                    <img src={image} />

                    <p className='textPrimary_header_card_avaliation'>{name}</p>
                </div>

                <p className='textSecondary_header_card_avaliation'>{description}</p>


                <div className='container_stars'>
                    <ReactStars
                        count={5}
                        edit={false}

                        size={24}
                        value={3}
                        color2={'#020066'} />

                </div>

            </div>


        </div>

    )
}

export default CardAvaliation