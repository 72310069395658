import React, { useState, useEffect, useContext } from 'react'
import Barcode from 'react-barcode';
import { v4 as uuidv4 } from 'uuid';

import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import HeaderDashboard from '../../components/HeaderDashboard'
import Sidebar from '../../components/Sidebar'
import Filter from '../../components/Filter'
import { VariationContext } from '../../context/variation';

import InputModal from '../../components/InputModal'
import TextArea from '../../components/TextArea';
import Select from '../../components/Select'
import CardVariation from '../../components/CardVariation'

import InputImageModal from '../../components/InputImageModal'
import TableListServices from '../../components/TableListServices'
import animationData from '../../images/animation/Animation - 1714666317337.json'

import 'react-datepicker/dist/react-datepicker.css';
import api from '../../services/api'
import Lottie from 'react-lottie';
import newItemIcon from '../../images/svg/portfolioImageIcon.e5576e8805da1b02b0055467ed0fdbb5.svg'

import { ServiceContext } from '../../context/service'
import { ToastContainer, toast } from 'react-toastify';
import app from '../../firebase/config'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
const Services = () => {
    const [animation, setAnimation] = useState(false)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const {
        idModal,
        nameModal,

        item
    } = useContext(ServiceContext)

    const { setImagesFirebaseVariationAdd, imagesFirebaseVariationAdd } = useContext(VariationContext)

    const [categoryEngate, setCategoryEngate] = useState()
    const [userSelectTypeSession, setUserSelectTypeSession] = useState([])

    /* CATEGORYS */
    const [category, setCategory] = useState()
    const [categorys, setCategorys] = useState([])

    /* SERVICE */
    const [updateService, setUpdateService] = useState(0)

    /* FIREBASE */
    const [imagesFirebase, setImagesFirebase] = useState([])
    const [imagesFirebaseDatabase, setImagesFirebaseDatabase] = useState([])

    const [description, setDescription] = useState()

    const [imagesVariationPreview, setImagesVariationPreview] = useState([])


    const [price, setPrice] = useState()
    const [promotionalPrice, setPromotionalPrice] = useState()

    /* INFO ENGATE */
    const [name, setName] = useState()
    const [typeProduct, setTypeProduct] = useState([
        {
            "name": "Físico",
        },

        {
            "name": "Digital",

        }
    ])
    const [stock, setStock] = useState([
        {
            "name": "Sob Encomenda",
        },
        {
            "name": "Pronta entrega",
        },
    ])
    const [typeSession, setTypeSession] = useState([

        {
            "name": "Produtos em destaque",
        },


    ])
    const [variationsStorage, setAvariationsStorage] = useState([
        {
            uuid: uuidv4(),
            image: "",
            preview_image: "",
            index: 0,
            name: "",
            value: "",
            price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
        }
    ])

    const [variationsEdit, setAvariationsEdit] = useState([
        
    ])
    const [variations, setVariations] = useState([
        {
            uuid: uuidv4(),
            image: "",
            preview_image: "",
            index: 0,
            name: "",
            value: "",
            price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
        }
    ])
    const [variationsDatabase, setVariationsDatabase] = useState([])
    const [typeEngate, setTypeEngate] = useState([])
    const [models, setModels] = useState([])
    const [stockQtd, setStockQtd] = useState()
    const [barCode, setBarCode] = useState()
    const [sku, setSku] = useState()

    const [weight, setWeight] = useState()
    const [width, setWidth] = useState()

    const [length, setLenght] = useState()
    const [height, setHeight] = useState()

    const [selectTypeProduct, setSelectTypeProduct] = useState()
    const [selectStock, setSelectStock] = useState()
    const [imagesPreview, setImagesPreview] = useState([])
    const [imagesPreviewDatabase, setImagesPreviewDatabase] = useState([])
    const [dataBaseImages, setDataBaseImages] = useState([])

    const [model, setModel] = useState()

    const [inputVariations, setInputVariations] = useState([{
        image: "",
        preview_image: "",
        index: 0,
        name: "",
        value: "",
        price: "",
        weight: "",
        length: "",
        width: "",
        height: "",


    }])

    const storage = async () => {


        if (!name) {
            return toast.error("Informe um nome");
        }
        if (!selectTypeProduct) {
            return toast.error("Informe um tipo de produto");
        }
        if (!category) {
            return toast.error("Informe uma categoria");
        }
        if (!selectStock) {
            return toast.error("Informe o tipo de estoque");
        }
        if (!stockQtd) {
            return toast.error("Informe uma quantidade no estoque");
        }
        if (!sku) {
            return toast.error("Informe um SKU");
        }
        if (!barCode) {
            return toast.error("Informe um código de barras");
        }
        if (!userSelectTypeSession) {
            return toast.error("Informe uma sessão do produto");
        }


        const loadingToastId = toast.loading("Processando o pedido...");

        try {

            const storageProduct = await api.post("panel/storage/product/", {
                "name": name,
                "type_product": selectTypeProduct,
                "category": category,
                "session_product": userSelectTypeSession,
                "type_stock": selectStock,
                "model": model,
                "qtd_stock": stockQtd,
                "description": description,
                "sku": sku,
                "barCode": barCode,
                "weight": weight,
                "length": length,
                "variations": inputVariations,
                "engateCategory": categoryEngate,
                "description": localStorage.getItem("@CONTENT_DESCRIPTION"),
                "width": width,
                "height": height,
                "video": "aa",
                "price": price,
                "promotion_price": promotionalPrice
            });

            if (storageProduct.status === 200) {

                const idProduct = storageProduct.data.id;

                // Upload de imagens principais
                const uploadPromises = imagesFirebase.map((item, index) => {
                    return app.storage().ref(`/products/images/${item.name}`).put(item)
                        .then(() => {
                            console.log(`Imagem ${item.name} carregada com sucesso`);
                            return app.storage().ref(`/products/images/${item.name}`).getDownloadURL();
                        })
                        .then(image => {
                            console.log(`URL da imagem ${item.name}: ${image}`);
                            return api.post("panel/storage/product/image", {
                                "image": image,
                                "product_id": idProduct,
                                "index": index
                            });
                        })
                        .catch(error => {
                            console.error(`Erro ao carregar a imagem ${item.name}:`, error);
                        });
                });
                
                // Upload de variações de produtos
                const uploadPromisesVariation = variationsStorage.map((item) => {
                    return app.storage().ref(`/products/images/variations/${item.uuid}`).put(item.image)
                        .then(() => {
                            console.log(`Imagem ${item.uuid} carregada com sucesso`);
                            return app.storage().ref(`/products/images/variations/${item.uuid}`).getDownloadURL();
                        })
                        .then(image => {
                            console.log(`URL da imagem ${item.uuid}: ${image}`);
                            return api.post("panel/storage/variation/", {
                                "product_id": idProduct,
                                "product_name":name,
                                "image": image,
                                "name": item.name,
                                "value": item.value,
                                "price": item.price,
                                "weight": item.weight,
                                "length": item.length,
                                "width": item.width,
                                "height": item.height
                            });
                        })
                        .catch(error => {
                            console.error(`Erro ao carregar a variação ${item.uuid}:`, error);
                        });
                });
                
                // Upload de todas as variações de imagens
                const uploadPromisesAllImagesVariations = variationsStorage.map((data, index) => {
                    const imageFiles = imagesFirebaseVariationAdd.filter(item => item.uuid === data.uuid);
                
                    if (imageFiles.length === 0) {
                        console.log(`UUID não encontrado para a variação ${data.uuid}`);
                        return Promise.resolve({
                            "product_id": idProduct,
                            "value": data.value,
                            "images": [],
                            "index": index
                        });
                    }
                
                    const imageUploadPromises = imageFiles.map(imageFile => {
                        return app.storage().ref(`/products/images/variations/${imageFile.file.name}`).put(imageFile.file)
                            .then(() => {
                                return app.storage().ref(`/products/images/variations/${imageFile.file.name}`).getDownloadURL();
                            })
                            .then(image => {
                                return api.post("panel/storage/variation/image", {
                                    "product_id": idProduct,
                                    "value": data.value,
                                    "variation_id": data.name,
                                    "image": image,
                                    "index": index
                                });
                            })
                            .catch(error => {
                                console.error(`Erro ao carregar a imagem da variação ${data.uuid}:`, error);
                            });
                    });
                
                    return Promise.all(imageUploadPromises);
                });
                
                try {
                    await Promise.all([
                        ...uploadPromises,
                        ...uploadPromisesVariation,
                        ...uploadPromisesAllImagesVariations
                    ]);
                    console.log("Todas as imagens foram carregadas com sucesso.");
                } catch (error) {
                    console.error("Erro ao carregar algumas imagens:", error);
                }

                toast.update(loadingToastId, {
                    render: "Produto armazenado com sucesso!",
                    type: "success",
                    isLoading: false,
                    autoClose: 1000
                });

                setTimeout(() => {
                    window.location.href = "/dashboard/services"
                }, [1500])
            }

            if (storageProduct.status == 400) {
                toast.update(loadingToastId, {
                    render: "Produto já existente",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000
                });
            }

        } catch (error) {
            console.error("Erro ao armazenar produto ou imagens:", error);
            toast.update(loadingToastId, {
                render: "Erro ao armazenar produto ou imagens",
                type: "error",
                isLoading: false,
                autoClose: 5000
            });
        } finally {
            setAnimation(false);
        }
    };
    const updateProduct = async () => {

        try {
            const loadingToastId = toast.loading("Processando o pedido...");

            if(variationsEdit.length == 1){

                const globalItemId = item?.product_id

                // Upload de variações de produtos
                const uploadPromisesVariation = variationsEdit.map((item) => {
                    return app.storage().ref(`/products/images/variations/${item.uuid}`).put(item.image)
                        .then(() => {
                            console.log(`Imagem ${item.uuid} carregada com sucesso`);
                            return app.storage().ref(`/products/images/variations/${item.uuid}`).getDownloadURL();
                        })
                        .then(image => {
                            console.log(`URL da imagem ${item.uuid}: ${image}`);
                            return api.post("panel/storage/variation/", {
                                "product_id": globalItemId,
                                "image": image,
                                "name": item.name,
                                "value": item.value,
                                "price": item.price,
                                "weight": item.weight,
                                "length": item.length,
                                "width": item.width,
                                "height": item.height
                            });
                        })
                        .catch(error => {
                            console.error(`Erro ao carregar a variação ${item.uuid}:`, error);
                        });
                });
                
                // Upload de todas as variações de imagens
                const uploadPromisesAllImagesVariations = variationsEdit.map((data, index) => {
                    const imageFiles = imagesFirebaseVariationAdd.filter(item => item.uuid === data.uuid);
                
                    if (imageFiles.length === 0) {
                        console.log(`UUID não encontrado para a variação ${data.uuid}`);
                        return Promise.resolve({
                            "product_id": globalItemId,
                            "value": data.value,
                            "images": [],
                            "index": index
                        });
                    }
                
                    const imageUploadPromises = imageFiles.map(imageFile => {
                        return app.storage().ref(`/products/images/variations/${imageFile.file.name}`).put(imageFile.file)
                            .then(() => {
                                return app.storage().ref(`/products/images/variations/${imageFile.file.name}`).getDownloadURL();
                            })
                            .then(image => {
                                return api.post("panel/storage/variation/image", {
                                    "product_id": globalItemId,
                                    "value": data.value,
                                    "image": image,
                                    "index": index
                                });
                            })
                            .catch(error => {
                                console.error(`Erro ao carregar a imagem da variação ${data.uuid}:`, error);
                            });
                    });
                
                    return Promise.all(imageUploadPromises);
                });
                
                try {
                    await Promise.all([
                        ...uploadPromisesVariation,
                        ...uploadPromisesAllImagesVariations
                    ]);
                    
                    toast.update(loadingToastId, {
                        render: "Produto alterado com sucesso!",
                        type: "success",
                        isLoading: false,
                        autoClose: 1000
                    });
        
                    
                    setTimeout(() => {
                        window.location.href = "/dashboard/services"
                    }, [1500])

                } catch (error) {
                    console.error("Erro ao carregar algumas imagens:", error);
                }

            }
            if (imagesFirebaseDatabase) {

                const uploadPromises = imagesFirebaseDatabase.map((item, index) => {
                    return app.storage().ref(`/products/images/${item.name}`).put(item)
                        .then(() => app.storage().ref(`/products/images/${item.name}`).getDownloadURL())
                        .then(image => {
                            return api.post("panel/storage/product/image", {
                                "image": image,
                                "product_id": item?.product_id,
                                "index": index
                            });
                        });
                });

                await Promise.all(uploadPromises);

                toast.update(loadingToastId, {
                    render: "Produto alterado com sucesso!",
                    type: "success",
                    isLoading: false,
                    autoClose: 1000
                });

                return false
            }
          


        } catch (error) {
            console.error("Erro ao atualizar imagens do produto:", error);
            setAnimation(false);
        }
    };
    const handleChangeVariation = (index, name_input, target) => {
        if (name_input == index) {

            if (target.files[0] != null) {

                const { type } = target.files[0]

                const supportedTypes = ["image/png", "image/jpg", "image/jpeg"]
                const verifySupportedType = supportedTypes.includes(type)


                if (!verifySupportedType) {
                    return toast.error("Formato de imagem não suportado!")
                }

                const reader = new FileReader()
                reader.readAsDataURL(target.files[0])
                reader.onload = async () => {
                    inputVariations[index]["preview_image"] = reader.result
                    inputVariations[index]["image"] = target.files[0]

                    variations[index]["preview_image"] = reader.result
                    variations[index]["image"] = target.files[0]

                    variationsStorage[index]["image"] = target.files[0]

                    console.log(target.files[0])


                    setVariations([...variations])


                }


            }
            return false
        }


        inputVariations[index][name_input] = target.value
        variationsStorage[index][name_input] = target.value

    }
    const handleRedirectToProductPage = () => {
        window.open("https://tkgengates.com.br/product", "_blank");
        localStorage.setItem("@PRODUCT_ID", idModal)
    }
    const handleEditVariation = (index, name_input, value) => {
        console.log(name_input)
        console.log(value)
        if (name == "") {
            return false
        }

        variationsEdit[index][name_input] = value
        console.log(...variationsEdit)
        setAvariationsEdit([...variationsEdit])

    }
    const handleEditVariationAdd = (index, name_input, value) => {

        if (name == "") {
            return false
        }

        variationsStorage[index][name_input] = value
        variationsStorage[index][name_input] = value
        setAvariationsStorage([...variationsStorage])

    }
    const handleVariation = async () => {

        const indexArray = variations.length

        const newVariation = {
            uuid: uuidv4(),
            index: indexArray,
            preview_image: "",
            image: "",
            name: "",
            value: "",
            price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
        }

        const newVariationStorage = {
            uuid: uuidv4(),
            index: indexArray,
            image: "",
            preview_image: "",
            name: "",
            value: "",
            price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
        }


        setInputVariations([...inputVariations, newVariation])
        setVariations([...variations, newVariation])
        setAvariationsStorage([...variationsStorage, newVariationStorage])
    }
    const handleVariationEdit = async () => {

        const newVariation = {
            uuid: uuidv4(),
            preview_image: "",
            image: "",
            name: "",
            value: "",
            price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
        }


        setAvariationsEdit([...variationsEdit, newVariation])
    }
    const handleRemoveVariation = async (id) => {

        const response = await api.delete(`panel/delete/variation/${id}`)
        if (response.status == 200) {
            setVariationsDatabase((item) => item.filter(item => item.id !== id));

        }

    }
    const handleRemove = (index) => {
        const updatedVariations = [...variationsStorage]; // Cria uma cópia da lista original
        updatedVariations.splice(index, 1); // Remove 1 item a partir do índice fornecido
        setAvariationsStorage(updatedVariations); // Atualiza o estado com a nova lista
    }
    const handleRemoveVariationsUpdate = (index) => {
        const updatedVariations = [...variationsEdit]; // Cria uma cópia da lista original
        updatedVariations.splice(index, 1); // Remove 1 item a partir do índice fornecido
        setAvariationsEdit(updatedVariations); // Atualiza o estado com a nova lista
    }
    const handleImage = (file) => {

        if (file) {

            const { type } = file

            const supportedTypes = ["image/png", "image/jpg", "image/jpeg"]
            const verifySupportedType = supportedTypes.includes(type)


            if (!verifySupportedType) {
                return toast.error("Formato de imagem não suportado!")
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {

                setImagesPreview([...imagesPreview, reader.result])
                setImagesFirebase([...imagesFirebase, file])
                console.log(imagesFirebase)
            }

        }

    }
    const handleImageDatabase = (file) => {
        if (file) {

            const { type } = file

            const supportedTypes = ["image/png", "image/jpg", "image/jpeg"]
            const verifySupportedType = supportedTypes.includes(type)


            if (!verifySupportedType) {
                return toast.error("Formato de imagem não suportado!")
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {

                setImagesPreviewDatabase([...imagesPreviewDatabase, reader.result])
                setImagesFirebaseDatabase([...imagesFirebaseDatabase, file])

            }

        }

    }
    const handleRemoveImage = (index) => {

        const updateImages = [...imagesPreview]
        updateImages.splice(index, 1)
        setImagesPreview(updateImages)

    }
    const handleRemoveImageDatabase = (index) => {

        const updateImages = [...imagesPreviewDatabase]
        updateImages.splice(index, 1)
        setImagesPreviewDatabase(updateImages)
    }
    const deleteService = async () => {

        const loadingToastId = toast.loading("Processando o pedido...");
        const response = await api.delete(`panel/product/${idModal}`);


        if (response.status === 200) {

            toast.update(loadingToastId, {
                render: "Produto removido com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 1000
            });

            setTimeout(() => {
                window.location.href = "/dashboard/services"
            }, [2000])


        }
    };

    const handleUpdate = async (field, value, form) => {
        try {

            if (form == "edit") {

                const response = await api.put("panel/product/update", {
                    field,
                    value,
                    id: item.id
                });

                if (response.status === 200) {
                    // Sucesso na atualização
                    setUpdateService(!updateService)
                    console.log("Produto atualizado com sucesso:", response.data);
                } else {
                    // Tratamento de erro
                    console.error("Erro ao atualizar o produto:", response.data);
                }
            }

        } catch (error) {
            // Tratamento de erro
            console.error("Erro ao atualizar o produto:", error);
        }
    };
    const handleDeleteDatabaseImage = async (index, id) => {

        const response = await api.delete(`panel/product/image/${id}`)
        const updateImages = [...dataBaseImages]
        updateImages.splice(index, 1)
        setDataBaseImages(updateImages)


    }
    const getProductImages = async () => {
        const response = await api.get(`/panel/product/images/${item?.product_id}`)
        if (response.status == 200) {
            setDataBaseImages(response.data)
        }
    }
    const getAllCategorysMenu = async () => {
        const response = await api.get("panel/get/all/categorys/menu")

        if (response.status == 200) {
            setTypeEngate(response.data)
        }

    }
    const getCategorys = async () => {

        const response = await api.get("panel/get/all/categorys")
        if (response.status == 200) {
            setCategorys(response.data)
        }
    }
    const getModels = async () => {
        const response = await api.get("panel/get/all/models")
        if (response.status == 200) {
            setModels(response.data)
            return false
        }


        setModels([])


    }
    const getVariations = async (id) => {
        const response = await api.get(`panel/get/variation/${id}`)
        if (response.status == 200) {
            setVariationsDatabase(response.data)
            return false
        }


    }

    const getAllImagesVariations = async (id) => {
        const response = await api.get(`panel/get/all/images/variations/${id}`)
        if (response.status == 200) {
            setImagesVariationPreview(response.data)
            return false
        } else {
            setImagesVariationPreview([])
        }
    }

    useEffect(() => {
        getAllCategorysMenu()
        getModels()
        getCategorys()

    }, [])



    useEffect(() => {

        setName(item?.name)
        setCategory(item?.category)
        setSelectStock(item?.type_stock)
        setCategoryEngate(item?.engateCategory)
        setSku(item?.sku)
        setBarCode(item?.barCode)
        setWeight(item?.weight)
        setStockQtd(item?.qtd_stock)
        setWidth(item?.width)
        setLenght(item?.length)
        setHeight(item?.height)
        const calcPrice = Number(item?.price) * 100
        const calcPromotionalPrice = Number(item?.promotion_price) * 100
        setPrice(String(calcPrice))
        setPromotionalPrice(String(calcPromotionalPrice))
        getProductImages()
        getVariations(item?.product_id)
        getAllImagesVariations(item?.product_id)
    }, [item])

    return (
        <ThowColumLayoutDashboard
            background={"#F7F9FB"}
            colum1={
                <Sidebar />
            }

            colum2={

                <div className='container_secondary'>

                    <ToastContainer style={{zIndex:"99999999999999999"}} />

                    {animation == true


                        ?
                        <div className='container_animation_data'>

                            <div>

                                <Lottie
                                    options={defaultOptions}
                                    height={240}
                                    width={450}
                                />
                            </div>
                        </div>
                        :
                        <></>

                    }

                    <div className='container_data'>
                        <div >
                            <HeaderDashboard name={"Produtos"} />
                            <Filter type={4} />
                            <TableListServices update={updateService} />

                        </div>
                    </div>

                    <div class="modal fade" id="service" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>
                                        <button style={{ background: "none" }}>
                                            <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 " fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>

                                        </button>


                                        <div><p>Adicionar Produto</p></div>


                                        <button data-dismiss="modal" aria-label="Close" onClick={() => storage()}>Adicionar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>


                                        <p className='text_type_form'>Informações Iniciais</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="Nome *" onChange={(e) => setName(e.target.value)} />
                                            <Select type={"category"} name="Tipo de produto*" onChange={(e) => setSelectTypeProduct(e.target.value)} data={typeProduct} />
                                        </div>

                                        <div className='row_input_modal_user'>
                                            <Select type={"category"} name="Marca*" onChange={(e) => setCategory(e.target.value)} data={categorys} />
                                            <Select type={"category"} name="Estoque *" onChange={(e) => setSelectStock(e.target.value)} data={stock} />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            {selectStock == "Sob Encomenda"

                                                ?
                                                <InputModal name="Prazo de fabricação (dias) *" onChange={(e) => setStockQtd(e.target.value)} mask={"9999999999999"} />

                                                :
                                                <InputModal name="Qtd stock (pronta entrega) *" onChange={(e) => setStockQtd(e.target.value)} mask={"9999999999999"} />

                                            }

                                            <Select type={"category"} name="Categoria*" onChange={(e) => setCategoryEngate(e.target.value)} data={typeEngate} />

                                        </div>
                                        <div className='row_input_modal_user'>
                                            <Select type={"category"} name="Sessão do produto*" onChange={(e) => setUserSelectTypeSession(e.target.value)} data={typeSession} />
                                        </div>

                                        <TextArea name="Descrição *" type={"00"} />

                                        <p className='text_type_form'>Códigos</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="SKU *" onChange={(e) => setSku(e.target.value)} />
                                            <InputModal name="Código de barras *" onChange={(e) => setBarCode(e.target.value)} mask={"99999999999999999999999"} />
                                        </div>

                                        {barCode == "" || barCode == undefined

                                            ?
                                            <></>
                                            :
                                            <div style={{ height: "30px" }}> </div>
                                        }

                                        {barCode == "" || barCode == undefined

                                            ?
                                            <></>
                                            :
                                            <div className='row_input_modal_user'>

                                                <div className='preview_bar_code'>
                                                    <p>Preview BarCode</p>
                                                    <Barcode value={barCode} marginRight={0} marginLeft={0} />
                                                </div>
                                            </div>

                                        }

                                        {barCode == ""

                                            ?
                                            <></>
                                            :
                                            <div style={{ height: "60px" }}> </div>

                                        }

                                        <p className='text_type_form'>Peso e dimensões </p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="Peso (KG)*" mask="99999" onChange={(e) => setWeight(e.target.value)} />
                                            <InputModal name="Comprimento*" onChange={(e) => setLenght(e.target.value)} mask={"999999999"} />
                                        </div>


                                        <div className='row_input_modal_user'>
                                            <InputModal name="Largura *" onChange={(e) => setWidth(e.target.value)} mask={"999999999"} />
                                            <InputModal name="Altura *" onChange={(e) => setHeight(e.target.value)} mask={"999999999"} />
                                        </div>



                                        <p className='text_type_form'>Imagens e Vídeos</p>

                                        <div className='row_input_modal_user'>
                                            <div>
                                                <label htmlFor='input_image_modal'>
                                                    <InputImageModal onChange={(e) => handleImage(e.target.files[0])} placeholder="" type="file" name="Imagem*" />
                                                </label>
                                            </div>

                                            <div className='container_local_service_data'>

                                                {imagesPreview?.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div className='box_local_images'>
                                                                <p className='remove_item_local_image' onClick={() => handleRemoveImage(index)}>X</p>
                                                                <img src={"data:image/;base64;" + item} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>


                                        </div>

                                        <p className='text_type_form'>Informações de Lucro</p>


                                        <div className='row_input_modal_user'>
                                            <InputModal type={"money"} value={price} onChange={(e) => setPrice(e.target.value)} name="Preço (R$)" />
                                            <InputModal type={"money"} value={promotionalPrice} onChange={(e) => setPromotionalPrice(e.target.value)} name="Preço Promocional (R$)" />
                                        </div>

                                        <p className='text_type_form'>Variações</p>


                                        {variationsStorage?.map((item, index) => {
                                            return (

                                                <div style={{ marginTop: "1.9rem" }}>
                                                    <p className='textPrimary_variation'>{item.name}</p>
                                                    <div style={{ display: "flex", flexDirection: "colum", paddingLeft: "38px" }}>
                                                        <CardVariation uuid={item.uuid} image={item.preview_image} onChange={(e) => handleChangeVariation(index, e.target.name, e.target)} onBlur={(e) => handleEditVariationAdd(index, e.target.name, e.target.value)} index={index} name="Estoque" />
                                                        <p className='remove_icon_stock' onClick={() => handleRemove(index)}>x</p>
                                                    </div>
                                                </div>

                                            )

                                        })}


                                        <div className='row_input_modal_user'>
                                            <img src={newItemIcon} className='image_add_newIcon' onClick={() => handleVariation(variations)} />
                                        </div>






                                        <div style={{ height: "2rem" }}></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="editService" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-user" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>
                                        <button style={{ background: "none" }}>
                                            <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 " fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>

                                        </button>


                                        <div><p>Editar Produto</p></div>


                                        <button data-dismiss="modal" aria-label="Close" onClick={() => updateProduct()}>Editar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>


                                        <p className='text_type_form'>Informações Iniciais</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="Nome *" onBlur={() => handleUpdate("name", name, "edit")} onChange={(e) => setName(e.target.value)} value={name} />
                                            <Select type={"category"} onBlur={() => handleUpdate("type_product", selectTypeProduct, "edit")} name="Tipo de produto*" value={"Físico"} onChange={(e) => setSelectTypeProduct(e.target.value)} data={typeProduct} />
                                        </div>

                                        <div className='row_input_modal_user'>
                                            <Select type={"category"} onBlur={() => handleUpdate("type_product", selectTypeProduct, "edit")} name="Marca*" value={category} onChange={(e) => setCategory(e.target.value)} data={categorys} />
                                            <Select type={"category"} name="Estoque *" value={selectStock} onChange={(e) => setSelectStock(e.target.value)} data={stock} />
                                        </div>



                                        <div className='row_input_modal_user'>
                                            {selectStock == "Sob Encomenda"

                                                ?
                                                <InputModal name="Prazo de fabricação (dias) *" onBlur={() => handleUpdate("qtd_stock", stockQtd, "edit")} mask={"9999999999999"} />

                                                :
                                                <InputModal name="Qtd stock (pronta entrega) *" onBlur={() => handleUpdate("qtd_stock", stockQtd, "edit")} mask={"9999999999999"} />

                                            }

                                            <Select type={"category"} name="Categoria*" onChange={(e) => setCategoryEngate(e.target.value)} data={typeEngate} />

                                        </div>


                                        <TextArea name="Descrição *" id={item?.id} type={"update"} description={item?.description} />

                                        <p className='text_type_form'>Códigos</p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="SKU *" onBlur={() => handleUpdate("sku", sku, "edit")} onChange={(e) => setSku(e.target.value)} value={sku} />
                                            <InputModal name="Código de barras *" onBlur={() => handleUpdate("barCode", barCode, "edit")} onChange={(e) => setBarCode(e.target.value)} mask={"99999999999999999999999"} value={barCode} />
                                        </div>

                                        {barCode == "" || barCode == undefined

                                            ?
                                            <></>
                                            :
                                            <div style={{ height: "30px" }}> </div>
                                        }

                                        {barCode == "" || barCode == undefined

                                            ?
                                            <></>
                                            :
                                            <div className='row_input_modal_user'>

                                                <div className='preview_bar_code'>
                                                    <p>Preview BarCode</p>
                                                    <Barcode marginRight={0} marginLeft={0} value={barCode} />
                                                </div>
                                            </div>

                                        }

                                        {barCode == ""

                                            ?
                                            <></>
                                            :
                                            <div style={{ height: "60px" }}> </div>

                                        }

                                        <p className='text_type_form'>Peso e dimensões </p>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="Peso (KG)*" mask={"99999999"} onBlur={() => handleUpdate("weight", weight, "edit")} onChange={(e) => setWeight(e.target.value)} value={weight} />
                                            <InputModal name="Comprimento*" onBlur={() => handleUpdate("length", length, "edit")} onChange={(e) => setLenght(e.target.value)} mask={"999999999"} value={length} />
                                        </div>

                                        <div className='row_input_modal_user'>
                                            <InputModal name="Largura*" onBlur={() => handleUpdate("width", width, "edit")} onChange={(e) => setWidth(e.target.value)} mask={"999999999"} value={width} />
                                            <InputModal name="Altura*" onBlur={() => handleUpdate("height", height, "edit")} onChange={(e) => setHeight(e.target.value)} mask={"999999999"} value={height} />
                                        </div>



                                        <p className='text_type_form'>Imagens e Vídeos</p>

                                        <div className='row_input_modal_user'>
                                            <div>
                                                <label htmlFor='input_image_modal'>
                                                    <InputImageModal onChange={(e) => handleImageDatabase(e.target.files[0])} placeholder="" type="file" name="Imagem*" />
                                                </label>
                                            </div>

                                            <div className='container_local_service_data'>

                                                {dataBaseImages?.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div className='box_local_images'>
                                                                <p className='remove_item_local_image' onClick={() => handleDeleteDatabaseImage(index, item.id)}>X</p>
                                                                <img src={item.image} />
                                                            </div>
                                                        </>
                                                    )
                                                })}


                                                {imagesPreviewDatabase?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className='box_local_images'>
                                                                <p className='remove_item_local_image' onClick={() => handleRemoveImageDatabase(index)}>X</p>
                                                                <img src={"data:image/;base64;" + item} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>


                                        </div>


                                        <p className='text_type_form'>Informações de Lucro</p>


                                        <div className='row_input_modal_user'>
                                            <InputModal type={"money"} onChange={(e) => setPrice(e.target.value)} value={price} name="Preço (R$)" />
                                            <InputModal type={"money"} onChange={(e) => setPromotionalPrice(e.target.value)} value={promotionalPrice} name="Preço Promocional (R$)" />
                                        </div>

                                        <p className='text_type_form'>Variações</p>


                                        {variationsDatabase?.map((item, index) => {
                                            return (

                                                <div style={{ marginTop: "1.9rem" }}>
                                                    <p className='textPrimary_variation'>{item.name}</p>
                                                    <div style={{ display: "flex", flexDirection: "colum", paddingLeft: "38px" }}>
                                                        <CardVariation type={"edit"} item={item} index={index} name="Estoque" />
                                                        <p className='remove_icon_stock' onClick={() => handleRemoveVariation(item.id)}>x</p>
                                                    </div>
                                                </div>

                                            )

                                        })}


                                        {variationsEdit?.map((item, index) => {
                                            return (

                                                <div style={{ marginTop: "1.9rem" }}>
                                                    <p className='textPrimary_variation'>{item.name}</p>
                                                    <div style={{ display: "flex", flexDirection: "colum", paddingLeft: "38px" }}>
                                                        <CardVariation uuid={item.uuid} type="add" image={item.preview_image}  onBlur={(e) => handleEditVariation(index, e.target.name, e.target.value)} index={index} name="Estoque" />
                                                        <p className='remove_icon_stock' onClick={() => handleRemoveVariationsUpdate(index)}>x</p>
                                                    </div>
                                                </div>

                                            )

                                        })}


                                        <div className='row_input_modal_user'>
                                            <img src={newItemIcon} className='image_add_newIcon' onClick={() => handleVariationEdit(variations)} />
                                        </div>






                                        <div style={{ height: "2rem" }}></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="options" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px", border: "none" }}>
                                    <div className='container_header_modal_user'>
                                        <p>Ações</p>
                                        <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                    </div>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>




                                    <div className='container_modal_options'>
                                        <button className='btn_secondary_modal_option' data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#removeUser">Excluir Produto</button>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="removeUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px" }}>
                                    <div className='container_header_modal_user'>

                                        <div className='row_remove_user_modal'>
                                            <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>
                                            <p>Excluir produto</p>

                                        </div>
                                    </div>
                                    <button className='remove_user' data-dismiss="modal" aria-label="Close" onClick={() => deleteService()}>Excluir</button>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_remove'>

                                        <p className='textPrimary_modal_remove'>Tem certeza que deseja excluir o produto <b>{nameModal}</b> ?</p>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            }

        >

        </ThowColumLayoutDashboard>
    )
}

export default Services