import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import app from '../firebase/config';
import api from '../services/api';
export const CarrouselContext = createContext();

export const CarrouselProvider = ({ children }) => {
    const [stateBtn,setStateBtn] = useState(true)
    const [topCarrouselData, setTopCarrouselData] = useState([])
    const [horizontalCarrouselData, setHorizontalCarrouselData] = useState([])
    const [verticalCarrousel, setVerticalCarrousel] = useState([])
    const [imageUpdateVertical, setImageUpdateVertical] = useState()
    const [TopCarrouselFirebase, setTopCarrouselFirebase] = useState([])
    const [verticalCarrouselFirebase, setVerticalCarrouselFirebase] = useState([])
    const [horizontalCarrouselFirebase, setHorizontalcalCarrouselFirebase] = useState([])

    const [images, setImages] = useState([])

    const getImages = async () => {

        const responseTop = await api.get("client/banner/1")
        if (responseTop.status == 200) {
            setTopCarrouselData(responseTop.data)
        }
        const responseHozizontal = await api.get("client/banner/2")
        if (responseHozizontal.status == 200) {
            setHorizontalCarrouselData(responseHozizontal.data)
        }

        const responseVertical = await api.get("client/banner/3")
        if (responseVertical.status == 200) {
            setVerticalCarrousel(responseVertical.data)
        }
    }
    const handleStorage = async () => {
        try {
            setStateBtn(false)
            const loadingToastId = toast.loading("Processando imagens...");

            // Upload de imagens banner header
            const uploadPromisesHeader = TopCarrouselFirebase.map((item, index) => {
                const random = Math.random()
                return app.storage().ref(`/banner/images/${random}`).put(item.file)
                    .then(() => {
                        console.log(`Imagem ${random} carregada com sucesso`);
                        return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                    })
                    .then(image => {
                        console.log(`URL da imagem ${random}: ${image}`);
                        return api.post("panel/storage/banner/", {
                            "mode": item.mode,
                            "image": image,
                        });
                    })
                    .catch(error => {
                        console.error(`Erro ao carregar a imagem ${item.name}:`, error);
                    });
            });
            // Upload de imagens banner horizontal
            const uploadPromisesHorizontal = horizontalCarrouselFirebase.map((item, index) => {
                const random = Math.random()
                return app.storage().ref(`/banner/images/${random}`).put(item.file)
                    .then(() => {
                        console.log(`Imagem ${random} carregada com sucesso`);
                        return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                    })
                    .then(image => {
                        console.log(`URL da imagem ${random}: ${image}`);
                        return api.post("panel/storage/banner/", {
                            "mode": item.mode,
                            "image": image,
                        });
                    })
                    .catch(error => {
                        console.error(`Erro ao carregar a imagem ${item.name}:`, error);
                    });
            });
            // Upload de imagens banner vertical
            const uploadPromisesVertical = verticalCarrouselFirebase.map((item, index) => {
                const random = Math.random()
                return app.storage().ref(`/banner/images/${random}`).put(item.file)
                    .then(() => {
                        console.log(`Imagem ${random} carregada com sucesso`);
                        return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                    })
                    .then(image => {
                        console.log(`URL da imagem ${random}: ${image}`);
                        return api.post("panel/storage/banner/", {
                            "mode": item.mode,
                            "image": image,
                        });
                    })
                    .catch(error => {
                        console.error(`Erro ao carregar a imagem ${item.name}:`, error);
                    });
            });

            await Promise.all([
                ...uploadPromisesHorizontal,
                ...uploadPromisesVertical,
                ...uploadPromisesHeader,
            ]);


            toast.update(loadingToastId, {
                render: "Sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 1000
            });
            window.location.href="/dashboard/banners"
            setStateBtn(true)

        } catch (error) {

        }
    }
    const handleRemoveTopCarrousel = async (index, id) => {
        const response = await api.delete(`panel/delete/banner/${id}`)

        const copy = [...topCarrouselData]
        copy.splice(index, 1)
        setTopCarrouselData(copy)
        toast.success("Imagem deletada com sucesso!",{
            render: "Sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 1000
        })


    }
    const handleUpdateCarrousel = (e, index,id) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 670 && image.height === 90) {

                        
                        const loadingToastId = toast.loading("Processando imagem...");

                        const random = Math.random()

                        app.storage().ref(`/banner/images/${random}`).put(file)
                            .then(() => {
                                console.log(`Imagem ${random} carregada com sucesso`);
                                return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                            })
                            .then(image => {
                                console.log(`URL da imagem ${random}: ${image}`);
                                return api.put("/panel/update/banner", {
                                    "mode": 1,
                                    "image": image,
                                    "id": id,
                                });
                            })
                            .catch(error => {
                                console.error(`Erro ao carregar a imagem ${random}:`, error);
                            });


                        toast.update(loadingToastId, {
                            render: "Imagem alterada com sucesso!",
                            type: "success",
                            isLoading: false,
                            autoClose: 1000
                        });


                        topCarrouselData[index]['image'] = e.target.result;
                        setTopCarrouselData([...topCarrouselData]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 670x90.');
                    }
                };
            };
        }
    };
    const handleTopCarrousel = (e, mode) => {
        const file = e.target.files[0];
        const random = Math.random()

        if (file) {
            setTopCarrouselFirebase([...TopCarrouselFirebase, { file: file, mode: mode }])
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 670 && image.height === 90) {
                        setTopCarrouselData([...topCarrouselData, { image: e.target.result, number: random }]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 670x90.');
                    }
                };
            };
        }
    };
    const moveImageUp = async (index,id,mode) => {
        if (index > 0) {
            const updatedData = [...topCarrouselData];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index - 1];
            updatedData[index - 1] = temp;
            setTopCarrouselData(updatedData);
            
               
            const response = await api.put("panel/move/banner/top", {
                "id": id,
                "direction": "up",

            })

        }
    };
    const moveImageDown = async (index,id,mode) => {
        if (index < topCarrouselData.length - 1) {
            const updatedData = [...topCarrouselData];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index + 1];
            updatedData[index + 1] = temp;
            setTopCarrouselData(updatedData);
        }

              
        const response = await api.put("panel/move/banner/top", {
            "id": id,
            "direction": "up",

        })
    };


    const handleRemoveHorizontalCarrousel = async (index, id) => {
        const response = await api.delete(`panel/delete/banner/${id}`)

        const copy = [...horizontalCarrouselData]
        copy.splice(index, 1)
        setHorizontalCarrouselData(copy)

        const copyFirebase = [...horizontalCarrouselFirebase]
        copyFirebase.splice(index, 1)
        setHorizontalcalCarrouselFirebase(copyFirebase)

        toast.success("Imagem deletada com sucesso!",{
            render: "Sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 1000
        })

    }
    const handleUpdateHorizontalCarrousel = (e, index,id,mode) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 1665 && image.height === 1080) {

                        const loadingToastId = toast.loading("Processando imagem...");

                        const random = Math.random()

                        app.storage().ref(`/banner/images/${random}`).put(file)
                            .then(() => {
                                console.log(`Imagem ${random} carregada com sucesso`);
                                return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                            })
                            .then(image => {
                                console.log(`URL da imagem ${random}: ${image}`);
                                return api.put("/panel/update/banner", {
                                    "mode": mode,
                                    "image": image,
                                    "id": id,
                                });
                            })
                            .catch(error => {
                                console.error(`Erro ao carregar a imagem ${random}:`, error);
                            });


                        toast.update(loadingToastId, {
                            render: "Imagem alterada com sucesso!",
                            type: "success",
                            isLoading: false,
                            autoClose: 1000
                        });

                        horizontalCarrouselData[index]['image'] = e.target.result;
                        setHorizontalCarrouselData([...horizontalCarrouselData]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 1665x1080.');
                    }
                };
            };
        }
    };
    const handleHorizontalCarrousel = (e, mode) => {
        const file = e.target.files[0];
        const random = Math.random()

        if (file) {

            setHorizontalcalCarrouselFirebase([...horizontalCarrouselFirebase, { file: file, mode: mode }])
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 1665 && image.height === 1080) {
                        setHorizontalCarrouselData([...horizontalCarrouselData, { image: e.target.result, number: random }]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 1665x1080.');
                    }
                };
            };
        }
    };
    const moveImageUpHorizontalCarrousel = async (index,id,mode) => {
        if (index > 0) {
            const updatedData = [...horizontalCarrouselData];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index - 1];
            updatedData[index - 1] = temp;
            setHorizontalCarrouselData(updatedData);
            
            const response = await api.put("panel/move/banner/horizontal", {
                "id": id,
                "direction": "up",
                "mode":mode

            })


        }
    };
    const moveImageDownHorizontalCarrousel = async (index,id,mode) => {
        if (index < horizontalCarrouselData.length - 1) {
            const updatedData = [...horizontalCarrouselData];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index + 1];
            updatedData[index + 1] = temp;
            setHorizontalCarrouselData(updatedData);
            const response = await api.put("panel/move/banner/horizontal", {
                "id": id,
                "direction": "down",
                "mode":mode

            })
        }
    };

    const handleRemoveVerticalCarrousel = async (index, id) => {

        const response = await api.delete(`panel/delete/banner/${id}`)

        toast.success("Imagem deletada com sucesso!",{
            render: "Sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 1000
        })
        const copy = [...verticalCarrousel]
        copy.splice(index, 1)
        setVerticalCarrousel(copy)

        const copyFirebase = [...verticalCarrouselFirebase]
        copyFirebase.splice(index, 1)
        setVerticalCarrouselFirebase(copyFirebase)


    }
    const handleUpdateVerticalCarrousel = (e, index, id, mode) => {
        const file = e.target.files[0];

        if (file) {


            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 1080 && image.height === 1080) {

                        const loadingToastId = toast.loading("Processando imagem...");

                        const random = Math.random()

                        app.storage().ref(`/banner/images/${random}`).put(file)
                            .then(() => {
                                console.log(`Imagem ${random} carregada com sucesso`);
                                return app.storage().ref(`/banner/images/${random}`).getDownloadURL();
                            })
                            .then(image => {
                                console.log(`URL da imagem ${random}: ${image}`);
                                return api.put("/panel/update/banner", {
                                    "mode": mode,
                                    "image": image,
                                    "id": id,
                                });
                            })
                            .catch(error => {
                                console.error(`Erro ao carregar a imagem ${random}:`, error);
                            });


                        toast.update(loadingToastId, {
                            render: "Imagem alterada com sucesso!",
                            type: "success",
                            isLoading: false,
                            autoClose: 1000
                        });

                        verticalCarrousel[index]['image'] = e.target.result;

                        setVerticalCarrousel([...verticalCarrousel]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 1080x1080.');
                    }
                };
            };
        }
    };
    const handleVerticalCarrousel = (e, mode) => {

        if (verticalCarrousel.length >= 6) {
            return toast.error("Limite de imagens atingido!")
        }

        const file = e.target.files[0];
        const random = Math.random()


        if (file) {
            setVerticalCarrouselFirebase([...verticalCarrouselFirebase, { file: file, mode: mode }])
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;

                image.onload = () => {
                    if (image.width === 1080 && image.height === 1080) {
                        setVerticalCarrousel([...verticalCarrousel, { image: e.target.result, number: random }]);
                    } else {
                        alert('A imagem precisa ter as dimensões de 1080x1080.');
                    }
                };
            };
        }
    };
    const moveImageUpVerticalCarrousel = async (index, id,mode) => {
        if (index > 0) {
            const updatedData = [...verticalCarrousel];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index - 1];
            updatedData[index - 1] = temp;
            setVerticalCarrousel(updatedData);

            const response = await api.put("panel/move/banner/", {
                "id": id,
                "direction": "up",
                "mode":mode,

            })


        }
    };
    const moveImageDownVerticalCarrousel = async (index, id,mode) => {
        if (index < verticalCarrousel.length - 1) {
            const updatedData = [...verticalCarrousel];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index + 1];
            updatedData[index + 1] = temp;
            setVerticalCarrousel(updatedData);
        }

        const response = await api.put("panel/move/banner", {
            "id": id,
            "direction": "down",
            "mode":mode,


        })
    };

    useEffect(() => {

        getImages()
    }, [])


    return (
        <CarrouselContext.Provider value={{
            setTopCarrouselData,
            handleTopCarrousel,
            handleRemoveTopCarrousel,
            handleUpdateCarrousel,
            moveImageUp,
            moveImageDown,
            topCarrouselData,

            handleHorizontalCarrousel,
            handleRemoveHorizontalCarrousel,
            handleUpdateHorizontalCarrousel,
            moveImageUpHorizontalCarrousel,
            moveImageDownHorizontalCarrousel,
            setHorizontalCarrouselData,
            horizontalCarrouselData,

            moveImageUpVerticalCarrousel,
            moveImageDownVerticalCarrousel,

            handleVerticalCarrousel,
            handleUpdateVerticalCarrousel,
            handleRemoveVerticalCarrousel,
            verticalCarrousel,
            setImageUpdateVertical,
            handleStorage,
            stateBtn,
            images


        }}>
            {children}
        </CarrouselContext.Provider>
    );
};
