import React,{createContext,useEffect,useState} from 'react'

export const VariationContext = createContext({})

function VariationProvider({children}){

    const [imagesFirebaseVariationAdd,setImagesFirebaseVariationAdd] = useState([])


    useEffect(() => {

        console.log(imagesFirebaseVariationAdd)

    },[imagesFirebaseVariationAdd])

    return(
    <VariationContext.Provider value={{setImagesFirebaseVariationAdd,imagesFirebaseVariationAdd}}>
        {children}
    </VariationContext.Provider>
    )

}

export default VariationProvider