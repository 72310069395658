import React, { createContext, useState } from 'react'
import api from '../services/api'


export const ServiceContext = createContext({})

function ServiceProvider({ children }) {
    const [item,setItem] = useState()
    const [servicesModal,setServicesModal] = useState()
    const [idModal,setIdModal] = useState()
    const [categoryModal, setCategoryModal] = useState()
    const [nameModal, setNameModal] = useState()
    const [descriptionModal, setDescriptionModal] = useState()
    const [codeModal, setCodeModal] = useState()
    const [imageModal, setImageModal] = useState()
    const [defaultDurationModal, setDefaultDurationModal] = useState()

    const [product,setProduct] = useState()

    const [costModal, setCostModal] = useState()
    const [profitModal, setProfitModal] = useState()
    const [percentageModal, setPercentageModal] = useState()
    const [salleModal, setSalleModal] = useState()
    const [comissionModal, setComissionModal] = useState()
    const [liquidModal, setLiquidModal] = useState()


    const getService = (
        id,
        name,
        description,
        code,
        image,
        duration,
        cost,
        profit,
        percentage,
        salle,
        comission,
        liquid,
        item,

    ) => {
        setProduct(id)
        setIdModal(id)
        setNameModal(name)

        setDescriptionModal(description)
        setCodeModal(code)
        setImageModal(image)
        setDefaultDurationModal(duration)
        setCostModal(cost)
        setProfitModal(profit)
        setPercentageModal(percentage)
        setSalleModal(salle)
        setComissionModal(comission)
        setLiquidModal(liquid)
        setItem(item)
    }

    
    const getProductByName = async (name) => {
        const response = await api.get(`panel/product/by/name/${name}`)
        
        if(response.status == 200){
            setServicesModal(response.data)
            return false
        }else{
            const categorys =  await api.get("panel/all/products")
            setServicesModal(categorys.data)
        }

    }

    return (
        <ServiceContext.Provider value={{
            idModal,
            categoryModal,
            nameModal,
            descriptionModal,
            codeModal,
            imageModal,
            defaultDurationModal,
            costModal,
            profitModal,
            percentageModal,
            salleModal,
            comissionModal,
            liquidModal,
            getProductByName,
            getService,
            servicesModal,
            product,
            setItem,
            item

        }}>
            {children}
        </ServiceContext.Provider>
    )

}

export default ServiceProvider