import React, { useEffect, useState, useContext } from 'react'
import InputModal from '../InputModal'
import { v4 as uuidv4 } from 'uuid';
import api from '../../services/api';
import { VariationContext } from '../../context/variation';
import { ToastContainer, toast } from 'react-toastify';
import app from '../../firebase/config'

const CardVariation = ({ index, onBlur, onChange, image, type, item, uuid }) => {

    const { setImagesFirebaseVariationAdd, imagesFirebaseVariationAdd } = useContext(VariationContext)

    const [imagesPreview, setPreviewImages] = useState([])
    const [imagesPreviewAdd, setImagesPreviewAdd] = useState([])

    const [imagesDatabasePreview, setImagesDatabasePreview] = useState([])

    const [name, setName] = useState()
    const [value, setValue] = useState()
    const [price, setPrice] = useState()
    const [weight, setWeight] = useState()
    const [length, setLength] = useState()
    const [height,setHeight] = useState()
    const [width, setWidth] = useState()

    const handleImage = (file) => {

        if (file) {

            const { type } = file

            const supportedTypes = ["image/png", "image/jpg", "image/jpeg"]
            const verifySupportedType = supportedTypes.includes(type)

            if (!verifySupportedType) {
                return toast.error("Formato de imagem não suportado!")
            }

            const loadingToastId = toast.loading("Processando o pedido...");

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {

                 app.storage().ref(`/products/images/variations/${file.name}`).put(file)
                .then(() => {
                    return app.storage().ref(`/products/images/variations/${file.name}`).getDownloadURL();
                })
                .then(image => {
                    return api.post("panel/storage/variation/image", {
                        "product_id": item?.product_id,
                        "value": item?.value,
                        "image": image,
                        "index": index
                    });

                })
                .catch(error => {
                    console.error(`Erro ao carregar a imagem da variação `, error);
                });

                setPreviewImages([...imagesPreview, reader.result])


            }

            toast.update(loadingToastId, {
                render: "Imagem armazenada com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 1000
            })
        }

    }
    const handleRemoveImageAdd = (index) => {

        const imagesPreviewAddCopy = [...imagesPreviewAdd]
        imagesPreviewAddCopy.splice(index, 1)
        setImagesPreviewAdd(imagesPreviewAddCopy)


    }
    const handleRemoveImageEdit = async (imageId) => {
        const response = await api.delete(`panel/delete/image/variation/${imageId}`)
        setImagesDatabasePreview((prevImages) => prevImages.filter(image => image.id !== imageId));
    };
    const handleRemoveImageEditPreview = async (imageId) => {
        setPreviewImages((prevImages) => prevImages.filter(item => item !== imageId));
    }
    const handleImageAdd = (file) => {

        if (file) {

            const { type } = file

            const supportedTypes = ["image/png", "image/jpg", "image/jpeg"]
            const verifySupportedType = supportedTypes.includes(type)


            if (!verifySupportedType) {
                return toast.error("Formato de imagem não suportado!")
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {



                setImagesPreviewAdd([...imagesPreviewAdd, reader.result])
                setImagesFirebaseVariationAdd([...imagesFirebaseVariationAdd, { file: file, uuid: uuid }])
            }

        }

    }
    const handleUpdate = async (field, value) => {
        try {
            const response = await api.put("panel/update/variation/", {
                field,
                value,
                id: item.id
            });

            if (response.status === 200) {

                console.log("Produto atualizado com sucesso:", response.data);
            } else {
                // Tratamento de erro
                console.error("Erro ao atualizar o produto:", response.data);
            }


        } catch (error) {
            // Tratamento de erro
            console.error("Erro ao atualizar o produto:", error);
        }
    };
    const getAllImages = async (value, id) => {
        const response = await api.post(`panel/get/all/images/variations/`, {
            value: value,
            product_id: id
        })
        if (response.status == 200) {
            setImagesDatabasePreview(response.data)
            return false
        }
    }

    useEffect(() => {

        if (item) {
            setName(item.name)
            setValue(item.value)
            setPrice(item.price)
            setWeight(item.weight)
            setLength(item.length)
            setWidth(item.width)
            setHeight(item.height)
        }

        getAllImages(item?.value, item?.product_id)
    }, [item])

    return (
        <>
            {type == "edit"

                ?
                <div className='card_variation'>
                    <ToastContainer />

                    {/* <div className='box_card_variation'>
                        {item.image != undefined
                            ?

                            <label htmlFor={uuidv4}>

                                <input  type='file' name={uuidv4} id={uuidv4} />
                                <img src={item.image} style={{ width: "40px", height: "40px", objectFit: "contain", borderRadius: "100%" }} />

                            </label>

                            :

                            <label htmlFor={uuidv4}>

                                <input  type='asddsadsasadds' name={uuidv4} id={uuidv4} />
                                <svg width="42" height="42" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1" y="1" width="26.5714" height="26.7914" rx="13.2857" fill="#FBB12D" stroke="white" stroke-width="2" />
                                    <path d="M17.859 10.7938C17.4959 10.7938 17.1626 10.5839 16.9959 10.26L16.5673 9.39023C16.2935 8.8444 15.5792 8.39453 14.9721 8.39453H13.609C12.9959 8.39453 12.2816 8.8444 12.0078 9.39023L11.5792 10.26C11.4126 10.5839 11.0792 10.7938 10.7161 10.7938C9.42447 10.7938 8.40066 11.8915 8.484 13.1871L8.79352 18.1416C8.86495 19.3772 9.52567 20.3909 11.1685 20.3909H17.4066C19.0495 20.3909 19.7042 19.3772 19.7816 18.1416L20.0911 13.1871C20.1745 11.8915 19.1507 10.7938 17.859 10.7938ZM13.3947 11.5436H15.1804C15.4245 11.5436 15.6269 11.7475 15.6269 11.9935C15.6269 12.2394 15.4245 12.4433 15.1804 12.4433H13.3947C13.1507 12.4433 12.9483 12.2394 12.9483 11.9935C12.9483 11.7475 13.1507 11.5436 13.3947 11.5436ZM14.2876 18.0636C13.1804 18.0636 12.2757 17.1579 12.2757 16.0362C12.2757 14.9146 13.1745 14.0088 14.2876 14.0088C15.4007 14.0088 16.2995 14.9146 16.2995 16.0362C16.2995 17.1579 15.3947 18.0636 14.2876 18.0636Z" fill="white" />
                                </svg>
                            </label>
                        }

                    </div> */}

                    <div className='container_primary_card_variation'>
                        <InputModal name={"Nome *"} input_name="name" onChange={(e) => { setName(e.target.value) }} value={name} onBlur={(e) => handleUpdate("name", e.target.value)} type={"variation"} width={"50px"} />
                        <InputModal name={"Valor *"} input_name="value" onChange={(e) => setValue(e.target.value)} value={value} onBlur={(e) => handleUpdate("value", e.target.value)} type={"variation"} width={"50px"} />
                        <InputModal name={"Preço *"} input_name="price" onChange={(e) => setPrice(e.target.value)} value={price} onBlur={(e) => handleUpdate("price", e.target.value)} type={"variation"} width={"50px"} />
                        <InputModal name={"Peso *"} input_name="weight" onChange={(e) => setWeight(e.target.value)} value={weight} onBlur={(e) => handleUpdate("weight", e.target.value)} mask={"99999999999"} type={"variation"} width={"50px"} />

                        <InputModal name={"Comprimento *"} input_name="length" onChange={(e) => setLength(e.target.value)} value={length} onBlur={(e) => handleUpdate("length", e.target.value)} type={"variation"} width={"50px"} />
                        <InputModal name={"Largura *"} input_name="width" onChange={(e) => setWidth(e.target.value)} value={width} onBlur={(e) => handleUpdate("width", e.target.value)} type={"variation"} width={"50px"} />
                        <InputModal name={"Altura *"} input_name="height" onChange={(e) => setHeight(e.target.value)} value={height} onBlur={(e) => handleUpdate("height", e.target.value)} mask={"99999999999"}   type={"variation"} width={"50px"} />

                        <InputModal name={"Imagens *"} input_name="file" uuid={uuidv4()} onChange={(e) => handleImage(e.target.files[0])} type={"file"} width={"50px"} />

                        <div className='container_images_card_variation'>

                            {imagesDatabasePreview?.map(item => {
                                return (
                                    <div>
                                        <p className='remove_image_card_variation' onClick={() => handleRemoveImageEdit(item.id)}>X</p>
                                        <img src={item.image} />
                                    </div>

                                )
                            })}

                            {imagesPreview?.map(item => {
                                return (
                                    <div>
                                        <p className='remove_image_card_variation' onClick={() => handleRemoveImageEditPreview(item)}>X</p>
                                        <img src={"data:image/;base64;" + item} />
                                    </div>
                                )
                            })}

                        </div>


                    </div>





                </div>
                :

                <div className='card_variation'>

                    {/* <div className='box_card_variation'>
                        {image != ""
                            ?

                            <label htmlFor={uuidv4}>

                                <input onChange={onChange} type='file' name={index} id={uuidv4} />
                                <img src={"data:image/;base64;" + image} style={{ width: "45px", height: "45px", objectFit: "contain", borderRadius: "100%" }} />

                            </label>

                            :

                            <label htmlFor={uuidv4}>

                                <input onChange={onChange} type='file' name={index} id={uuidv4} />
                                <svg width="42" height="42" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1" y="1" width="26.5714" height="26.7914" rx="13.2857" fill="#FBB12D" stroke="white" stroke-width="2" />
                                    <path d="M17.859 10.7938C17.4959 10.7938 17.1626 10.5839 16.9959 10.26L16.5673 9.39023C16.2935 8.8444 15.5792 8.39453 14.9721 8.39453H13.609C12.9959 8.39453 12.2816 8.8444 12.0078 9.39023L11.5792 10.26C11.4126 10.5839 11.0792 10.7938 10.7161 10.7938C9.42447 10.7938 8.40066 11.8915 8.484 13.1871L8.79352 18.1416C8.86495 19.3772 9.52567 20.3909 11.1685 20.3909H17.4066C19.0495 20.3909 19.7042 19.3772 19.7816 18.1416L20.0911 13.1871C20.1745 11.8915 19.1507 10.7938 17.859 10.7938ZM13.3947 11.5436H15.1804C15.4245 11.5436 15.6269 11.7475 15.6269 11.9935C15.6269 12.2394 15.4245 12.4433 15.1804 12.4433H13.3947C13.1507 12.4433 12.9483 12.2394 12.9483 11.9935C12.9483 11.7475 13.1507 11.5436 13.3947 11.5436ZM14.2876 18.0636C13.1804 18.0636 12.2757 17.1579 12.2757 16.0362C12.2757 14.9146 13.1745 14.0088 14.2876 14.0088C15.4007 14.0088 16.2995 14.9146 16.2995 16.0362C16.2995 17.1579 15.3947 18.0636 14.2876 18.0636Z" fill="white" />
                                </svg>
                            </label>
                        }

                    </div> */}

                    <div className='container_primary_card_variation'>
                        <InputModal name={"Nome"} input_name="name" onChange={onChange} onBlur={onBlur} type={"variation"} width={"50px"} />
                        <InputModal name={"Valor"} input_name="value" onChange={onChange} onBlur={onBlur} type={"variation"} width={"50px"} />
                        <InputModal name={"Preço"} input_name="price" onChange={onChange} onBlur={onBlur} type={"variation"} width={"50px"} />
                        <InputModal name={"Peso"} input_name="weight" onChange={onChange} onBlur={onBlur} mask={"99999999999"} type={"variation"} width={"50px"} />

                        <InputModal name={"Comprimento"} input_name="length" onChange={onChange} onBlur={onBlur} type={"variation"} width={"50px"} />
                        <InputModal name={"Largura"} input_name="width" onChange={onChange} onBlur={onBlur} type={"variation"} width={"50px"} />
                        <InputModal name={"Altura"} input_name="height" onChange={onChange} onBlur={onBlur} mask={"99999999999"} type={"variation"} width={"50px"} />
                        <InputModal name={"Imagens *"} input_name="file" uuid={uuidv4()} onChange={(e) => handleImageAdd(e.target.files[0])} type={"file"} width={"50px"} />

                        <div className='container_images_card_variation'>

                            {imagesPreviewAdd?.map((item, index) => {
                                return (
                                    <div>
                                        <p className='remove_image_card_variation' onClick={() => handleRemoveImageAdd(index)}>X</p>
                                        <img src={"data:image/;base64;" + item} />
                                    </div>

                                )
                            })}

                        </div>


                    </div>




                </div>

            }

        </>
    )
}

export default CardVariation