import React, { useState, useEffect, useContext } from 'react'
import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import HeaderDashboard from '../../components/HeaderDashboard'
import Sidebar from '../../components/Sidebar'
import TableList from '../../components/TableListModels'
import Filter from '../../components/Filter'
import InputImageModal from '../../components/InputImageModal'
import { CategoryContext } from '../../context/categorys'
import { ServiceContext } from '../../context/service'
import { FilterContext } from '../../context/filter'
import InputModal from '../../components/InputModal'

import app from '../../firebase/config'
import animationData from '../../images/animation/Animation - 1714666317337.json'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../services/api'

import axios from 'axios'
const Models = () => {
    const [brazilianStates, setBrazilianStates] = useState([])
    const [userSelectState, setUserSelectState] = useState()
    const [image, setImage] = useState()
    const [citys, setCitys] = useState([])
    const [table, setTable] = useState(1)
    const [openSidebar, setOpenSidebar] = useState(false)
    const {  product } = useContext(ServiceContext)

    const { categoryId, categoryName, subCategoryModal } = useContext(CategoryContext)
    const { get, typeTable } = useContext(FilterContext)

    /* UPDATE INFO */
    const [update, setUpdate] = useState(false)

    /* CATEGORY INFO */
    const [category, setCategory] = useState()
    const [subCategory, setSubCategory] = useState()
    const [userName, setUserName] = useState()
    const [animation, setAnimation] = useState(null)

    const [imageCategoryFirebase, setImageCategoryFirebase] = useState()
    const [imageCategoryPreview, setImageCategoryPreview] = useState()

    useEffect(() => {

        if (categoryId) {
            setCategory(categoryName)
            setSubCategory(subCategoryModal)
        }

    }, [categoryId])

    const categoryImagePreview = (file) => {


        setImageCategoryFirebase(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {

            setImageCategoryPreview(reader.result)

        }

    }
    const storage = async () => {

        const loadingToastId = toast.loading("Processando o pedido...");

        const response = await api.post("/panel/storage/category/menu", {
            "name": category,
        })
        if (response.status == 200) {

            toast.update(loadingToastId, {
                render: "Categoria adicionada com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 1000
            });

            
            
            setTimeout(() => {
                window.location.href="/dashboard/models"
            },[2000])    
        }


        // app.storage().ref(`/models/${imageCategoryFirebase.name}`).put(imageCategoryFirebase).then(() => {
        //     app.storage().ref(`/models/${imageCategoryFirebase.name}`).getDownloadURL().then(async (image) => {

                
        //     const response = await api.post("/panel/storage/category/menu", {
        //         "name": category,
        //         "image": image
        //     })
        //     if (response.status == 200) {
        //         toast.success('Categoria adicionada com sucesso!', {
        //             position: "top-right",
        //             autoClose: 2000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: "light",
        //         });
        //         setUpdate(!update)
        //     }


        //     }).catch(error => {
        //         // window.alert(error);
        //     });
        // }).catch(error => {
        //     // window.alert(error);
        // });




    }

    const remove = async () => {

        const loadingToastId = toast.loading("Processando o pedido...");

        const response = await api.post("panel/delete/menu/category", {
            id: categoryId
        })

        if (response.status == 200) {

            
            toast.update(loadingToastId, {
                render: "Categoria removida com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 1000
            });

            setTimeout(() => {
                window.location.href="/dashboard/models"
            },[2000])    
        }
    }
    const edit = async (field, value) => {
        const response = await api.put("panel/update/category/menu", {
            id: categoryId, // O id deve ser enviado junto com o campo e valor
            field,
            value,
        });
    
        return response.data; // Se você precisa do retorno
    }
    const handleEdit = () => {

        toast.success('Categoria alterada com sucesso!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(() => {

            window.location.href = "/dashboard/models"

        }, [1500])

    }

    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
            );

            console.log(response.data)
            setBrazilianStates(response.data);
        };

        getBrazilianStates();


    }, []);

    useEffect(() => {

        setTable(typeTable)

    }, [typeTable])

    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                `http://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/municipios`
            );

            setCitys(response.data);
        };


        if (userSelectState != undefined) {
            getBrazilianStates();

        }


    }, [userSelectState]);





    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "scroll",
        overFlowX: "hidden",
        alignItems: "center",

    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <ThowColumLayoutDashboard
            background={"white"}
            colum1={
                <Sidebar />
            }

            colum2={



                <div className='container_secondary'>

                    <ToastContainer />

                    <div className='container_data'>
                        <div >
                            <HeaderDashboard name={"Categorias"} />
                            <Filter type={5} />
                            <TableList update={update} />

                        </div>
                    </div>



                    <div class="modal fade" id="categoryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-category" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>

                                        <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                        <div style={{ width: "10px" }}></div>

                                        <p>Adicionar Categoria</p>


                                        <button onClick={() => storage()} data-dismiss="modal" aria-label="Close">Adicionar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>



                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <InputModal onChange={(e) => setCategory(e.target.value)} name="Nome *" />
                                   
                                        </div>

                                        <div style={{ height: "5vh" }}></div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="editCategoryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-category" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div className='container_header_modal_user'>

                                        <svg width="13" data-dismiss="modal" aria-label="Close" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                        <div style={{ width: "10px" }}></div>

                                        <p>Editar Categoria</p>


                                        <button onClick={() => handleEdit()} data-dismiss="modal" aria-label="Close">Editar</button>
                                    </div>

                                </div>
                                <div class="modal-body">


                                    <div className='container_modal_user'>



                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <InputModal value={category} onBlur={() => edit("name", category)} onChange={(e) => setCategory(e.target.value)} name="Nome *" />
                                        </div>

                                        <div style={{ height: "5vh" }}></div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="options" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px", border: "none" }}>
                                    <div className='container_header_modal_user'>
                                        <p>Ações</p>
                                        <svg data-dismiss="modal" aria-label="Close" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                        </svg>

                                    </div>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_options'>

                                        <button className='btn_secondary_modal_option' data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#removeUser">Excluir categoria</button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="removeUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  modal-dialog-centered modal-dialog-options " role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ height: "55px" }}>
                                    <div className='container_header_modal_user'>

                                        <div className='row_remove_user_modal'>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.97623 6.67528L12.7796 1.66678C13.0778 1.35585 13.0778 0.851727 12.7796 0.540818C12.4815 0.229884 11.998 0.229884 11.6999 0.540818L6.897 5.54983L2.09413 0.540818C1.79597 0.229884 1.31254 0.229884 1.0144 0.540818C0.716253 0.851751 0.716229 1.35587 1.0144 1.66678L5.81777 6.67528L1.0144 11.6838C0.716229 11.9947 0.716229 12.4988 1.0144 12.8098C1.31257 13.1207 1.79599 13.1207 2.09413 12.8098L6.897 7.80072L11.6999 12.8098C11.998 13.1207 12.4815 13.1207 12.7796 12.8098C13.0778 12.4988 13.0778 11.9947 12.7796 11.6838L7.97623 6.67528Z" fill="black" />
                                            </svg>
                                            <p style={{ fontSize: "1.0rem", marginTop: "0.3rem" }}>Excluir Categoria</p>

                                        </div>
                                    </div>
                                    <button onClick={() => remove()} className='remove_user' data-dismiss="modal" aria-label="Close">Excluir</button>

                                </div>
                                <div class="modal-body" style={{ paddingRight: "0px", paddingLeft: "0px" }}>

                                    <div className='container_modal_remove'>

                                        <p className='textPrimary_modal_remove'>Tem certeza que deseja excluir a categoria <b>{categoryName}</b> ?</p>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            }

        >

        </ThowColumLayoutDashboard>
    )
}

export default Models