import React, { useState } from 'react'

const Select = ({name,value,data,onChange,onBlur,type}) => {

    const [arrowState,setArrowState] = useState(false)

    return(
        type == "state" 
        
        ?
        <div onBlur={() => setArrowState(!arrowState)} onClick={() => setArrowState(!arrowState)} className='container_select_modal'>
        <label>{name}</label>
        <select onChange={onChange} className='select_modal_user'>
        <option value="" >{value ? value : 'Selecione'}</option>
                {data && data
                    .filter(item => item.name !== value)
                    .map(item => (
                        <option key={item.name} value={item.name}>
                            {item.name}
                        </option>
                    ))
                }

        </select>
        {arrowState == false 
        
        ?
        <div className='arrow_select'>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 7.6515C5.51543 7.6515 5.03086 7.41252 4.66397 6.94339L0.150562 1.17227C-0.0501875 0.91558 -0.0501875 0.490713 0.150562 0.234022C0.351312 -0.0226688 0.683588 -0.0226688 0.884338 0.234022L5.39775 6.00514C5.73003 6.43001 6.26997 6.43001 6.60225 6.00514L11.1157 0.234022C11.3164 -0.0226688 11.6487 -0.0226688 11.8494 0.234022C12.0502 0.490713 12.0502 0.91558 11.8494 1.17227L7.33603 6.94339C6.96914 7.41252 6.48457 7.6515 6 7.6515Z" fill="#888888"/>
        </svg>
        </div>   
        :

        <div className='arrow_select_invert'>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.02896 0.0418328C7.51352 0.0455198 7.99626 0.288187 8.35956 0.76009L12.8289 6.56539C13.0277 6.8236 13.0245 7.24845 12.8218 7.50361C12.6191 7.75876 12.2868 7.75624 12.088 7.49802L7.61867 1.69273C7.28964 1.26535 6.7497 1.26124 6.4142 1.68356L1.85701 7.42018C1.65432 7.67533 1.32205 7.6728 1.12326 7.41459C0.924466 7.15638 0.927699 6.73153 1.1304 6.47637L5.68759 0.739759C6.05804 0.273439 6.54441 0.0381458 7.02896 0.0418328Z" fill="#888888"/>
        </svg>
        </div>

        }
 

        </div>
        :

        <div onBlur={() => setArrowState(false)} onClick={() => setArrowState(!arrowState)} className='container_select_modal'>
        <label>{name}</label>
        <select onBlur={onBlur} onChange={onChange} className='select_modal_user'>
        <option value="" >{value ? value : 'Selecione'}</option>
                {data && data
                    .filter(item => item.name !== value)
                    .map(item => (
                        <option key={item.name} value={item.name}>
                            {item.name}
                        </option>
                    ))
                }

        </select>
        {arrowState == false 
        
        ?
        <div className='arrow_select'>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 7.6515C5.51543 7.6515 5.03086 7.41252 4.66397 6.94339L0.150562 1.17227C-0.0501875 0.91558 -0.0501875 0.490713 0.150562 0.234022C0.351312 -0.0226688 0.683588 -0.0226688 0.884338 0.234022L5.39775 6.00514C5.73003 6.43001 6.26997 6.43001 6.60225 6.00514L11.1157 0.234022C11.3164 -0.0226688 11.6487 -0.0226688 11.8494 0.234022C12.0502 0.490713 12.0502 0.91558 11.8494 1.17227L7.33603 6.94339C6.96914 7.41252 6.48457 7.6515 6 7.6515Z" fill="#888888"/>
        </svg>
        </div>   
        :

        <div className='arrow_select_invert'>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.02896 0.0418328C7.51352 0.0455198 7.99626 0.288187 8.35956 0.76009L12.8289 6.56539C13.0277 6.8236 13.0245 7.24845 12.8218 7.50361C12.6191 7.75876 12.2868 7.75624 12.088 7.49802L7.61867 1.69273C7.28964 1.26535 6.7497 1.26124 6.4142 1.68356L1.85701 7.42018C1.65432 7.67533 1.32205 7.6728 1.12326 7.41459C0.924466 7.15638 0.927699 6.73153 1.1304 6.47637L5.68759 0.739759C6.05804 0.273439 6.54441 0.0381458 7.02896 0.0418328Z" fill="#888888"/>
        </svg>
        </div>

        }
 

        </div>

    )
}

export default Select