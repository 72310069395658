import React,{useState,useContext, useEffect} from 'react'
import { FilterContext } from '../../context/filter'

const FilterType = () => {
    const {get,typeTable} = useContext(FilterContext)

    const [type,setType] = useState(1)

    useEffect(() => {

        setType(typeTable)

    },[typeTable])


    return(

        <div className='filter_type'>
        
        <button onClick={() => {setType(1);get(1)}} className={type == 1 ? 'check_filter_type' :'not_check_filter_type'}>Usuários</button>
        <button onClick={() => {setType(2);get(2)}} className={type == 2 ? 'check_filter_type' :'not_check_filter_type'}>Prestadores</button>
        </div>

    )
}

export default FilterType