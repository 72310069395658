import React, { useContext, useState } from 'react'
import { CarrouselContext } from '../../context/carrousel';

const CarrouselCard = ({ mode, index, item, id }) => {
    const {
        handleRemoveTopCarrousel,
        handleRemoveHorizontalCarrousel,
        handleUpdateHorizontalCarrousel,
        handleUpdateCarrousel,
        handleUpdateVerticalCarrousel,
        moveImageUp,
        moveImageDown,
        moveImageUpHorizontalCarrousel,
        moveImageDownHorizontalCarrousel,
        moveImageUpVerticalCarrousel,
        moveImageDownVerticalCarrousel,
        handleRemoveVerticalCarrousel,
        setImageUpdateVertical
    } = useContext(CarrouselContext)

    const [indexEdit, setIndexEdit] = useState()
    const handleUpdateImage = (e, index) => {
        handleUpdateVerticalCarrousel(e, index, id, mode); // Usa o índice diretamente
    };


    return (

        <div className='carrousel_card' key={index}>

            <div className='header_carrousel_card'>
                <svg onClick={() => mode == 1 ? handleRemoveTopCarrousel(index, id) : mode == 2 ? handleRemoveHorizontalCarrousel(index, id) : mode == 3 ? handleRemoveVerticalCarrousel(index, id) : <></>} width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.316 4.01649H15.3276C15.1039 2.88325 14.5119 1.865 13.6514 1.13337C12.791 0.40174 11.7147 0.00146052 10.604 0L8.676 0C7.56527 0.00146052 6.48899 0.40174 5.62855 1.13337C4.76812 1.865 4.17614 2.88325 3.9524 4.01649H0.964C0.708331 4.01649 0.463134 4.12228 0.282349 4.31059C0.101564 4.4989 0 4.7543 0 5.02061C0 5.28692 0.101564 5.54233 0.282349 5.73064C0.463134 5.91895 0.708331 6.02474 0.964 6.02474H1.928V19.0783C1.92953 20.4094 2.43784 21.6855 3.34144 22.6267C4.24503 23.5679 5.47013 24.0974 6.748 24.0989H12.532C13.8099 24.0974 15.035 23.5679 15.9386 22.6267C16.8422 21.6855 17.3505 20.4094 17.352 19.0783V6.02474H18.316C18.5717 6.02474 18.8169 5.91895 18.9977 5.73064C19.1784 5.54233 19.28 5.28692 19.28 5.02061C19.28 4.7543 19.1784 4.4989 18.9977 4.31059C18.8169 4.12228 18.5717 4.01649 18.316 4.01649ZM8.676 2.00825H10.604C11.2019 2.00901 11.785 2.20242 12.2733 2.56196C12.7615 2.92151 13.131 3.42957 13.3312 4.01649H5.94884C6.14895 3.42957 6.51847 2.92151 7.00673 2.56196C7.49498 2.20242 8.07806 2.00901 8.676 2.00825ZM15.424 19.0783C15.424 19.8773 15.1193 20.6435 14.577 21.2084C14.0346 21.7733 13.299 22.0907 12.532 22.0907H6.748C5.98099 22.0907 5.2454 21.7733 4.70305 21.2084C4.16069 20.6435 3.856 19.8773 3.856 19.0783V6.02474H15.424V19.0783Z" fill="black" />
                    <path d="M7.7118 18.0745C7.96747 18.0745 8.21267 17.9687 8.39345 17.7804C8.57424 17.5921 8.6758 17.3367 8.6758 17.0704V11.0456C8.6758 10.7793 8.57424 10.5239 8.39345 10.3356C8.21267 10.1473 7.96747 10.0415 7.7118 10.0415C7.45613 10.0415 7.21094 10.1473 7.03015 10.3356C6.84937 10.5239 6.7478 10.7793 6.7478 11.0456V17.0704C6.7478 17.3367 6.84937 17.5921 7.03015 17.7804C7.21094 17.9687 7.45613 18.0745 7.7118 18.0745Z" fill="black" />
                    <path d="M11.5675 18.074C11.8232 18.074 12.0684 17.9682 12.2492 17.7799C12.43 17.5916 12.5315 17.3362 12.5315 17.0699V11.0451C12.5315 10.7788 12.43 10.5234 12.2492 10.3351C12.0684 10.1468 11.8232 10.041 11.5675 10.041C11.3118 10.041 11.0666 10.1468 10.8859 10.3351C10.7051 10.5234 10.6035 10.7788 10.6035 11.0451V17.0699C10.6035 17.3362 10.7051 17.5916 10.8859 17.7799C11.0666 17.9682 11.3118 18.074 11.5675 18.074Z" fill="black" />
                </svg>

                <label htmlFor={`input_update_carrousel_${id}`} onClick={() => setIndexEdit(index)}>
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="44" height="44" rx="11.5" fill="white" />
                        <rect x="0.5" y="0.5" width="44" height="44" rx="11.5" stroke="black" />
                        <path d="M26.1642 23.5033L24.3583 25.6667L26.1642 27.83C26.485 28.215 26.4392 28.7925 26.045 29.1225C25.8708 29.2692 25.6692 29.3333 25.4583 29.3333C25.1925 29.3333 24.9358 29.2233 24.7525 29.0033L23.1667 27.0967L21.5808 29.0033C21.3975 29.2233 21.1408 29.3333 20.875 29.3333C20.6642 29.3333 20.4625 29.26 20.2883 29.1225C19.9033 28.8017 19.8483 28.2242 20.1692 27.83L21.975 25.6667L20.1692 23.5033C19.8483 23.1183 19.8942 22.5408 20.2883 22.2108C20.6825 21.89 21.2508 21.9358 21.5808 22.33L23.1667 24.2367L24.7525 22.33C25.0733 21.945 25.6508 21.89 26.045 22.2108C26.43 22.5317 26.485 23.1092 26.1642 23.5033ZM32.3333 20.6158V28.4167C32.3333 30.9467 30.28 33 27.75 33H18.5833C16.0533 33 14 30.9467 14 28.4167V15.5833C14 13.0533 16.0533 11 18.5833 11H22.7175C24.4317 11 26.045 11.6692 27.255 12.8792L30.445 16.0783C31.655 17.2883 32.3242 18.9017 32.3242 20.6158H32.3333ZM25.9625 14.1717C25.6692 13.8783 25.3483 13.6308 25 13.4292V17.4167C25 17.9208 25.4125 18.3333 25.9167 18.3333H29.895C29.6933 17.985 29.4458 17.6642 29.1525 17.3708L25.9625 14.1717ZM30.5 20.6067C30.5 20.46 30.5 20.3042 30.4817 20.1575H25.9167C24.4042 20.1575 23.1667 18.92 23.1667 17.4075V12.8517C23.02 12.8333 22.8733 12.8333 22.7175 12.8333H18.5833C17.0708 12.8333 15.8333 14.0708 15.8333 15.5833V28.4167C15.8333 29.9292 17.0708 31.1667 18.5833 31.1667H27.75C29.2625 31.1667 30.5 29.9292 30.5 28.4167V20.6158V20.6067Z" fill="black" />
                    </svg>
                </label>
            </div>

            <input type="file" id={`input_update_carrousel_${id}`} name={`input_update_carrousel_${id}`} onChange={(e) => { mode == 1 ? handleUpdateCarrousel(e, index, id) : mode == 3 ? handleUpdateImage(e, index) : mode == 2 ? handleUpdateHorizontalCarrousel(e, index, id, mode) : <></> }} />

            <button onClick={() => {
                if (mode === 3) {
                    moveImageUpVerticalCarrousel(index, id, 3);
                } else if (mode === 2) {
                    moveImageUpHorizontalCarrousel(index, id, 2);
                } else if (mode === 1) {
                    alert(id)
                    moveImageUp(index, id, 1);
                }
            }}>
                ↑
            </button>

            <button onClick={() => {
                if (mode === 3) {
                    moveImageDownVerticalCarrousel(index, id, 3);
                } else if (mode === 2) {
                    moveImageDownHorizontalCarrousel(index, id, 2);
                } else if (mode === 1) {
                    moveImageDown(index, id, 1);
                }
            }}>
                ↓
            </button>


            {mode == 1

                ?
                <img className='swiper-slide' src={item} />
                :
                mode == 2

                    ?
                    <img className='swiper-slide img' src={item} />

                    :
                    mode == 3

                        ?
                        <img className='swiper-slide img' src={item} />

                        :
                        <></>
            }
        </div>

    )
}

export default CarrouselCard