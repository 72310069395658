import React,{createContext,useState} from 'react'
import api from '../services/api'

export const UserContext = createContext({})

function UserProvider({children}){

    const [usersPanel,setUsersPanel] = useState([])
    const [productId,setProductId] = useState()
    const [userModal,setUserModal] = useState(false)
    const [userId,setUserId] = useState()
    const [userName,setUserName] = useState()
    const [userImage,setUserImage] = useState()
    const [localServices,setLocalServices] = useState([])
    const [stateInput,setStateInput] = useState()
    const [services,setServices] = useState([])
    const [request,setRequest] = useState()
    const closeModal = () => {
        setUserModal(false)
    }

    const handleAddService = (data) => {
        const item = localServices.filter(service => data.id == service.id);
        if(item != ""){
            return false
        }        
        setLocalServices([data, ...localServices])
        console.log(localServices)

        setStateInput(false)
        setServices([])
    }

    

    const handleRemoveService = (id) => {
        const updatedServices = localServices.filter(service => service.id !== id);
        setLocalServices(updatedServices);
    };

    const getProduct = (id,image,name) => {
        console.log(productId)
        setProductId(id)
        setUserModal(true)
        setUserImage(image)
        setUserName(name)
    }

    const getByName = async (name) => {

        const response = await api.get(`panel/user/by/name/${name}`)

        if(response.status == 200){
            setUsersPanel(response.data)
            return false
        }

        if(response.status == 404){
            setUsersPanel([])
            return false
        }
        
    }

    const getUser = () => {
        
    }

    const getRequest = async (id) => {
        const response = await api.get(`panel/get/info/request/${id}`)
        if(response.status == 200){
            setRequest(response.data[0])
        }
    }


    return(
    <UserContext.Provider value={{getProduct,getRequest,closeModal,getByName,getUser,handleAddService,handleRemoveService,localServices,userImage,userModal,userId,userName,usersPanel,productId,request}}>
        {children}
    </UserContext.Provider>
    )

}

export default UserProvider