import React from 'react';
import InputMask from 'react-input-mask';
import imageIcon from '../../images/svg/imageIcon.svg';

const InputModal = ({ name, input_name, type, mask, onChange, onBlur, value,uuid }) => {
    // Formata o valor apenas se o tipo for "money"
    const formattedValue = type === 'money' && value ? (value.replace(/\D/g, '') / 100).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") : value;

    return (
        <div className={type !== undefined ? "container_input_modal_variation" : "container_input_modal"}>
            <label>{name}</label>
            {type == 'money' ? (
                <InputMask
                    onBlur={onBlur}
                    mask={mask}
                    maskChar={false}
                    onChange={onChange}
                    value={formattedValue}
                    type="text"
                    className='input_modal'
                />
            ) : type === 'variation' ? (
                <InputMask
                    onBlur={onBlur}
                    mask={mask}
                    name={input_name}
                    maskChar={false}
                    onChange={onChange}
                    value={value}
                    type="text"
                    className='input_modal_variation'
                />
            ) : (

                type == "variation_money"

                    ?
                    <InputMask
                        onBlur={onBlur}
                        mask={mask}
                        maskChar={false}
                        onChange={onChange}
                        value={formattedValue}
                        type="text"
                        className='input_modal_variation'
                    />
                    :

                    type == "file"

                        ?
                        <div className='container_input_file_modal'>
                            <label htmlFor={uuid}>
                                <img src={imageIcon} />

                            </label>
                            <InputMask
                                name={uuid}
                                id={uuid}
                                onBlur={onBlur}
                                mask={mask}
                                maskChar={false}
                                onChange={onChange}
                                value={value}
                                type="file"
                                className='input_modal'
                            />
                        </div>
                        :

                        <InputMask
                            onBlur={onBlur}
                            mask={mask}
                            maskChar={false}
                            onChange={onChange}
                            value={value}
                            type="text"
                            className='input_modal'
                        />
            )}

            {type === 'textArea' && (
                <textarea className='input_modal' />
            )}
        </div>
    );
}

export default InputModal;
