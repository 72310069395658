import React, { useContext } from 'react'
import { CarrouselContext } from '../../context/carrousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import CarrouselCard from '../CarrouselCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

// import required modules
import { Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';


const VerticalCarrousel = ({ text, measure, type, mode }) => {

    const { topCarrouselData, horizontalCarrouselData, verticalCarrousel,images} = useContext(CarrouselContext)

    return (
        <div className='container_carrousel_data'>
            <p>{text} </p>
            <p>{measure}</p>

            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={false}

                slidesPerView={1}
                direction={type}
                spaceBetween={-100} // Espaçamento entre os slides para evitar sobreposição
                style={{ height: '450px' }} // Defina uma altura específica

                speed={1500}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 200,
                    modifier: 1,
                    slideShadows: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >


                {mode == 1

                    ?

                    topCarrouselData?.map((item, index) => {
                        console.log(item)
                        return (
                            <SwiperSlide>
                                <CarrouselCard mode={1}  id={item.id} index={index} item={item.image} />
                            </SwiperSlide>
                        )
                    })

                    :
                    mode == 2

                        ?
                        horizontalCarrouselData?.map((item, index) => {

                            return (
                                <SwiperSlide>
                                    <CarrouselCard mode={2} id={item.id} index={index} item={item.image} />
                                </SwiperSlide>
                            )
                        })
                        :

                        mode == 3

                            ?
                            verticalCarrousel?.map((item, index) => {


                                return (
                                    <SwiperSlide>
                                        <CarrouselCard mode={3} id={item.id} index={index} item={item.image} />
                                    </SwiperSlide>
                                )
                            })
                            :


                            <></>
                }


            </Swiper>
        </div>
    );
};

export default VerticalCarrousel;
